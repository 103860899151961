import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { Dispatch, FocusEvent, SetStateAction, useCallback, useMemo } from 'react';
import { Button, Form, Header, TextArea } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import { useListWebhookConfigsQuery } from 'src/api/webhooks';
import { Note, StyledFieldset } from 'src/styles';
import { DefaultEvaluationPrompt, LLMProviders, QualifaiConversation } from 'src/types';
import { cleanPhone } from 'src/utils';
import AccordionQuestions from './AccordionQuestions';
import { ValidationErrors } from './EditQualifaiConversationForm';
import { Layout } from './style';

// const PRETRANSFER_BLACKLIST_DISPOS = ['DNC', 'Irate', 'Prank', 'Prank Minor', 'Profanity'];
const betaURL = process.env.REACT_APP_BETA_URL;

type Props = {
  errors: ValidationErrors;
  formdata: QualifaiConversation;
  formdataKey: 'fronter' | 'closer';
  onChange: (event: any, data: any) => void;
  setFormdata: Dispatch<SetStateAction<QualifaiConversation>>;
  setSaved: Dispatch<SetStateAction<boolean>>;
};

const TabGenerativeAgent = ({ formdataKey, errors, formdata, onChange, setFormdata, setSaved }: Props) => {
  // const { data: scorecards, isLoading: scorecardsLoading } = useListQualifaiScorecardsQuery();
  const { data: user } = useGetUserProfileQuery();
  const { data: apiTokens } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });
  const { data: webhookConfigs, isLoading: webhookConfigsLoading } = useListWebhookConfigsQuery({
    limit: 100,
    offset: 0,
  });

  const onBlurPhone = useCallback(
    ({ currentTarget: { name, value } }: FocusEvent<HTMLInputElement, Element>) => {
      setFormdata(prev => {
        // Clean and Format Phone Numbers
        let v = cleanPhone(value);
        if (v.length > 0 && v.indexOf('sip:') === -1 && v.indexOf('+1') !== 0) {
          v = `+1${v}`;
        }

        const next = _cloneDeep(prev);
        _set(next, name, v);
        return next;
      });
      setSaved(false);
    },
    [setFormdata, setSaved]
  );

  const apiToken = useMemo(() => {
    let token = '{api_token}';
    if (apiTokens && apiTokens.data.length > 0) {
      token = apiTokens.data[0].token;
    }
    return token;
  }, [apiTokens]);

  const getWebhookURL = useCallback(
    (webhookID: string | undefined) => {
      if (!webhookID) return '';

      const webhook = webhookConfigs?.data.find(c => c.id === webhookID);
      if (!webhook) return '';

      return `${betaURL}/api/call-transfer/${webhook.id}?token=${apiToken}`;
    },
    [apiToken, webhookConfigs?.data]
  );

  // const addScorecardDataMapping = useCallback(() => {
  //   setFormdata(prev => {
  //     const next = _cloneDeep(prev);

  //     const agent = next[formdataKey] ?? newQualifaiConversationGenerativeAgent();

  //     if (!agent.scorecardDataMappings) {
  //       agent.scorecardDataMappings = [];
  //     }

  //     agent.scorecardDataMappings.push({
  //       item_id: '',
  //       mapping_type: 'answer',
  //       field_name: '',
  //       parse: false,
  //       regexp: '',
  //     });

  //     next[formdataKey] = agent;

  //     return next;
  //   });
  //   setSaved(false);
  // }, [formdataKey, setFormdata, setSaved]);

  // const removeScorecardDataMapping = useCallback(
  //   (index: number) => () => {
  //     setFormdata(prev => {
  //       const next = _cloneDeep(prev);
  //       if (!next[formdataKey]) return next;

  //       if (!next[formdataKey]?.scorecardDataMappings) {
  //         return next;
  //       }

  //       next[formdataKey]?.scorecardDataMappings.splice(index, 1);

  //       return next;
  //     });
  //     setSaved(false);
  //   },
  //   [formdataKey, setFormdata, setSaved]
  // );

  // const addScorecardTransferOverride = useCallback(() => {
  //   setFormdata(prev => {
  //     const next = _cloneDeep(prev);

  //     const agent = next[formdataKey] ?? newQualifaiConversationGenerativeAgent();

  //     if (!agent.scorecardTransferOverrides) {
  //       agent.scorecardTransferOverrides = [];
  //     }

  //     agent.scorecardTransferOverrides.push({
  //       disposition: '',
  //       override_type: 'allow_transfer',
  //       transfer_to: '',
  //     });

  //     next[formdataKey] = agent;

  //     return next;
  //   });
  //   setSaved(false);
  // }, [formdataKey, setFormdata, setSaved]);

  // const removeScorecardTransferOverride = useCallback(
  //   (index: number) => () => {
  //     setFormdata(prev => {
  //       const next = _cloneDeep(prev);
  //       if (!next[formdataKey]) return next;

  //       if (!next[formdataKey]?.scorecardTransferOverrides) {
  //         return next;
  //       }

  //       next[formdataKey]?.scorecardTransferOverrides.splice(index, 1);

  //       return next;
  //     });
  //     setSaved(false);
  //   },
  //   [formdataKey, setFormdata, setSaved]
  // );

  // const selectedScorecard = scorecards?.find(s => s.id === formdata[formdataKey]?.pretransferScorecardId);

  return (
    <Layout>
      <div style={{ gridArea: 'general' }}>
        {user?.role === 'admin' && (
          <StyledFieldset>
            <legend>Admin only</legend>

            <Form.Select
              clearable
              placeholder={LLMProviders[0]?.text || ''}
              label="LLM Provider"
              name={`${formdataKey}.llmProvider`}
              value={formdata[formdataKey]?.llmProvider || ''}
              onChange={onChange}
              options={LLMProviders.map(p => ({ ...p, key: p.value })) || []}
            />
          </StyledFieldset>
        )}

        <StyledFieldset>
          <legend>Overrides</legend>

          <Form.Input
            label="Transfer To"
            name={`${formdataKey}.transferTo`}
            value={formdata[formdataKey]?.transferTo || ''}
            onBlur={onBlurPhone}
            onChange={onChange}
          />
          <Note>
            When a prospect is qualified and ready to be transferred, they will be transferred to this phone number.
            This overrides the default transfer number in the voice config.
          </Note>

          <Form.Select
            clearable
            placeholder="Select a webhook"
            label="Call Transfer Webhook"
            value={formdata[formdataKey]?.callTransferWebhookId || ''}
            name={`${formdataKey}.callTransferWebhookId`}
            onChange={(e, data) => {
              onChange(e, data);
              onChange(e, {
                name: `${formdataKey}.callTransferUrl`,
                value: getWebhookURL(data.value as string),
              });
            }}
            options={webhookConfigs?.data.map(c => ({ key: c.id, value: c.id, text: c.name })) || []}
            loading={webhookConfigsLoading}
          />
          <Note>
            Right before the call is transferred to the number above, this API request will be fired. This overrides the
            default webhook in the voice config.
          </Note>
        </StyledFieldset>

        <Form.Field>
          <label>Call Evaluation Prompt</label>
          <Note>
            This is added to the end of our larger system prompt for the LLM and will be used in every interaction with
            the prospect. The LLM will use this list of categories / dispositions to make a decision after every phrase
            said by the prospect.
          </Note>
          <Note>The prompt immediately before this says:</Note>
          <Note>
            If at any point you choose to end the conversation or it reaches the end of the list of questions, provide
            your response as a "note" and the category as the "disposition" then put both into the json data.
          </Note>

          <TextArea
            placeholder={DefaultEvaluationPrompt}
            name={`${formdataKey}.evaluationPrompt`}
            value={formdata[formdataKey]?.evaluationPrompt}
            onChange={onChange}
            style={{ minHeight: '500px' }}
          />
        </Form.Field>

        <Form.Field>
          <Button
            type="button"
            color="blue"
            size="mini"
            compact
            onClick={() => onChange(null, { name: `${formdataKey}.evaluationPrompt`, value: DefaultEvaluationPrompt })}
          >
            Edit Default Prompt
          </Button>
        </Form.Field>

        <Form.Field>
          <label>Extra Instructions for System Prompt</label>
          <Note>
            These instructions will be added immediately after the above evaluation prompt and just before the end of
            the system prompt.
          </Note>

          <TextArea
            name={`${formdataKey}.extraInstructions`}
            value={formdata[formdataKey]?.extraInstructions}
            onChange={onChange}
            style={{ minHeight: '200px' }}
          />
        </Form.Field>

        {/* <Header>Transfer</Header>

        <Form.Select
          search
          clearable
          loading={scorecardsLoading}
          label="Pre-Transfer Evaluation Scorecard"
          name={`${formdataKey}.pretransferScorecardId`}
          onChange={onChange}
          value={formdata[formdataKey]?.pretransferScorecardId || ''}
          options={scorecards?.map(s => ({ key: s.id, value: s.id, text: s.title })) || []}
        />

        <Form.Field>
          <label>Transfer Overrides</label>
          <Note>
            By default we allow <strong>Qualified</strong> and <strong>Qualified Hesitant</strong> dispositions through
            to the transfer number specified in the voice config. If you would like to allow other dispositions to
            transfer you can add them here. You also have the option to override the transfer number based on the
            disposition.
          </Note>
          <Note>
            By default we blacklist the following dispositions:{' '}
            {PRETRANSFER_BLACKLIST_DISPOS.map((d, i) => (
              <Fragment key={d}>
                <strong>{d}</strong>
                {i < PRETRANSFER_BLACKLIST_DISPOS.length - 1 ? ', ' : ''}
              </Fragment>
            ))}
            . This can not be overridden.
          </Note>

          {(formdata[formdataKey]?.scorecardTransferOverrides || []).length > 0 && (
            <Table celled>
              <Table.Body>
                {(formdata[formdataKey]?.scorecardTransferOverrides || []).map((m: any, i: number) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Form.Select
                        clearable
                        search
                        label="Disposition"
                        name={`${formdataKey}.scorecardTransferOverrides.${i}.disposition`}
                        onChange={onChange}
                        value={m.disposition || ''}
                        options={(selectedScorecard?.sections || []).reduce((acc, s) => {
                          const item = s.scorecardItems.find(i => i.order === 0);
                          if (!item) return acc;

                          item.selectOptions
                            .filter(({ label }) => !PRETRANSFER_BLACKLIST_DISPOS.includes(label))
                            .forEach(({ label }) => {
                              acc.push({
                                key: label,
                                value: label,
                                text: label,
                              });
                            });

                          return acc;
                        }, [] as DropdownItemProps[])}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <label>Override Type</label>
                        <Select
                          clearable
                          placeholder="Allow Transfer"
                          name={`${formdataKey}.scorecardTransferOverrides.${i}.override_type`}
                          onChange={onChange}
                          value={m.override_type || ''}
                          options={[
                            {
                              key: 'allow_transfer',
                              value: 'allow_transfer',
                              text: 'Allow Transfer',
                            },
                            {
                              key: 'transfer_to',
                              value: 'transfer_to',
                              text: 'Transfer To',
                            },
                          ]}
                        />
                      </Form.Field>

                      {m.override_type === 'transfer_to' && (
                        <Form.Input
                          placeholder="+12223334444"
                          label="Phone Number"
                          name={`${formdataKey}.scorecardTransferOverrides.${i}.transfer_to`}
                          onChange={onChange}
                          value={m.transfer_to || ''}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Button type="button" icon color="red" onClick={removeScorecardTransferOverride(i)}>
                        <Icon name="trash" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}

          <Button type="button" color="blue" size="mini" compact onClick={addScorecardTransferOverride}>
            <Icon name="plus" />
            Add Override
          </Button>
        </Form.Field>

        <Form.Field>
          <label>Data Mappings</label>

          {(formdata[formdataKey]?.scorecardDataMappings || []).length > 0 && (
            <Table celled>
              <Table.Body>
                {(formdata[formdataKey]?.scorecardDataMappings || []).map((m: any, i: number) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Form.Select
                        clearable
                        search
                        label="Scorecard Item"
                        name={`${formdataKey}.scorecardDataMappings.${i}.item_id`}
                        onChange={onChange}
                        value={m.item_id || ''}
                        options={(selectedScorecard?.sections || []).reduce((acc, s) => {
                          s.scorecardItems.forEach(i => {
                            acc.push({
                              key: i.id,
                              value: i.id,
                              text: i.question,
                              description: i.reportingLabel,
                            });
                          });
                          return acc;
                        }, [] as DropdownItemProps[])}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <div style={{ float: 'right' }}>
                          <Checkbox
                            label="Parse?"
                            name={`${formdataKey}.scorecardDataMappings.${i}.parse`}
                            onChange={onChange}
                            checked={m.parse}
                          />
                        </div>

                        <label>Mapping Type</label>
                        <Select
                          clearable
                          placeholder="Anwser"
                          name={`${formdataKey}.scorecardDataMappings.${i}.mapping_type`}
                          onChange={onChange}
                          value={m.mapping_type || ''}
                          options={[
                            { key: 'answer', value: 'answer', text: 'Answer' },
                            { key: 'note', value: 'note', text: 'Note' },
                          ]}
                        />
                      </Form.Field>

                      {m.parse && (
                        <Form.Field>
                          <label>Regular Expression</label>
                          <Input
                            labelPosition="right"
                            name={`${formdataKey}.scorecardDataMappings.${i}.regexp`}
                            onChange={onChange}
                            value={m.regexp || ''}
                          >
                            <Label basic>/</Label>
                            <input />
                            <Label basic>/</Label>
                          </Input>
                        </Form.Field>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        label="Field Name"
                        name={`${formdataKey}.scorecardDataMappings.${i}.field_name`}
                        onChange={onChange}
                        value={m.field_name || ''}
                      />
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Button type="button" icon color="red" onClick={removeScorecardDataMapping(i)}>
                        <Icon name="trash" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}

          <Button type="button" color="blue" size="mini" compact onClick={addScorecardDataMapping}>
            <Icon name="plus" />
            Add Data Mapping
          </Button>
        </Form.Field> */}
      </div>

      <div style={{ gridArea: 'nodes' }}>
        <Note>
          You are an agent at a call center having a conversation with a potential customer. You are only allowed to say
          the Questions, Rebuttals, Fallbacks and Endings listed below. Do not say anything else.
        </Note>

        <Header>Questions</Header>
        <Note>
          You need to ask the following list of Questions. If the customer is agreeable and expresses interest in the
          offering, continue through the list of Questions.
        </Note>

        <AccordionQuestions
          errors={errors}
          formdata={formdata}
          onChange={onChange}
          setFormdata={setFormdata}
          setSaved={setSaved}
          formdataKey={formdataKey}
          questionsKey="questions"
          questionTitle="Question"
        />

        <Header>Rebuttals</Header>
        <Note>If the customer is hesitant and one of the Rebuttals makes sense to respond with, use it.</Note>

        <AccordionQuestions
          errors={errors}
          formdata={formdata}
          onChange={onChange}
          setFormdata={setFormdata}
          setSaved={setSaved}
          formdataKey={formdataKey}
          questionsKey="rebuttals"
          questionTitle="Rebuttal"
        />

        <Header>Fallbacks</Header>
        <Note>Do not answer general off topic questions, if one is asked, respond with one of the Fallbacks.</Note>

        <AccordionQuestions
          errors={errors}
          formdata={formdata}
          onChange={onChange}
          setFormdata={setFormdata}
          setSaved={setSaved}
          formdataKey={formdataKey}
          questionsKey="fallbacks"
          questionTitle="Fallback"
        />

        <Header>Hangup Endings</Header>
        <Note>
          If the customer is not interested or does not qualify for the offer based on their responses, return one of
          the "hangupEndings". Also set the "action" to hangup and provide a note about why the ending was used.
        </Note>

        <AccordionQuestions
          errors={errors}
          formdata={formdata}
          onChange={onChange}
          setFormdata={setFormdata}
          setSaved={setSaved}
          formdataKey={formdataKey}
          questionsKey="hangupEndings"
          questionTitle="Ending"
        />

        <Header>Transfer Endings</Header>
        <Note>
          If the customer makes it to the end of the list of "questions", provide one of these "transferEndings" as the
          response and set the "action" to transfer.
        </Note>

        <AccordionQuestions
          errors={errors}
          formdata={formdata}
          onChange={onChange}
          setFormdata={setFormdata}
          setSaved={setSaved}
          formdataKey={formdataKey}
          questionsKey="transferEndings"
          questionTitle="Ending"
        />
      </div>
    </Layout>
  );
};

export default TabGenerativeAgent;
