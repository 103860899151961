import { useState } from 'react';
import { Form, InputOnChangeData, Table } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useSaveVoiceConfigMutation } from 'src/api/voice-configs';
import { BillingConnectProType, BillingConnectProTypes, VoiceConfig } from 'src/types';

type Props = {
  config: VoiceConfig;
  defaultRate?: number;
  defaultType?: string;
};

const AdminAccountBillingVoiceConfigForm = ({ config, defaultRate, defaultType }: Props) => {
  const { isLoading, mutateAsync } = useSaveVoiceConfigMutation();
  const [billingRate, setBillingRate] = useState(String(config.billing_rate || ''));
  const [billingType, setBillingType] = useState(config.billing_type || '');
  const [error, setError] = useState<ApiMessageData>();

  const onChangeBillingRate = (_e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData): void => {
    if (!Number.isNaN(Number(value))) {
      setBillingRate(value);
    }
  };

  const onSubmit = async () => {
    setError(undefined);
    try {
      config.billing_rate = Number(billingRate) || 0;
      config.billing_type = billingType as BillingConnectProType;
      await mutateAsync({ config });
    } catch (e: any) {
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Table.Row key={config.id}>
      <Table.Cell>{config.name}</Table.Cell>
      <Table.Cell>
        <Form onSubmit={onSubmit}>
          <Form.Input
            width={6}
            placeholder={defaultRate || 'Use account default'}
            onChange={onChangeBillingRate}
            value={billingRate}
            loading={isLoading}
            onBlur={onSubmit}
            error={error && `${error.status} ${error.message}`}
          />
        </Form>
      </Table.Cell>
      <Table.Cell>
        <Form.Select
          clearable
          search
          placeholder={
            (defaultType && BillingConnectProTypes.find(v => v.value === defaultType)?.text) || 'Use account default'
          }
          options={BillingConnectProTypes.map(t => ({
            ...t,
            key: t.value,
          }))}
          onChange={(_e, { value }) => setBillingType(value as string)}
          value={billingType}
          onBlur={onSubmit}
          error={error && `${error.status} ${error.message}`}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default AdminAccountBillingVoiceConfigForm;
