import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { AccountPermission as AP } from 'src/types';
import {
  accountConnectProBillingIsCurrent,
  accountLeadscorePlusBillingIsCurrent,
  hasAccountAccessRoles,
  hasAccountPermission,
} from 'src/utils';
import OnboardingMenubar from './OnboardingMenu';
import { MenubarChildren, MenubarItem, MenubarSpacer, Nav, NavContainer } from './styles';

const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

const MENUBAR_KEY = 'menubar-collapsed';

const useMenubarState = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const isCollapsed = localStorage.getItem(MENUBAR_KEY);
    return isCollapsed === 'true';
  });

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed(collapsed => {
      localStorage.setItem(MENUBAR_KEY, JSON.stringify(!collapsed));
      return !collapsed;
    });
  }, [setIsCollapsed]);

  return {
    isCollapsed,
    toggleIsCollapsed,
  };
};

const Menubar = () => {
  const { data: user } = useGetUserProfileQuery();
  const { isCollapsed, toggleIsCollapsed } = useMenubarState();

  const location = useLocation();
  const isInOnboardingPage = location.pathname.startsWith('/onboarding');
  const isAdmin = user?.role === 'admin';

  const a = user?.active_account;

  useEffect(() => {
    if (isCollapsed) {
      document.body.classList.add('nav--collapsed');
    }

    return () => {
      document.body.classList.remove('nav--collapsed');
    };
  }, [isCollapsed]);

  if (isInOnboardingPage || (!a?.onboarding_completed_at && !isAdmin)) {
    return <OnboardingMenubar />;
  }

  const billingEnabled = a && a.billing.enabled && hasAccountAccessRoles(user, ['owner', 'billing']);

  const billingIssue = (a && !accountLeadscorePlusBillingIsCurrent(a)) || (a && !accountConnectProBillingIsCurrent(a));

  return (
    <NavContainer>
      <Nav>
        {(hasAccountPermission(user, AP.BLACKLISTS) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/blacklists">
              <Icon name="minus circle" />
              <span>Blacklists</span>
            </NavLink>
          </MenubarItem>
        )}

        {hasAccountPermission(user, AP.CONVOSO) && (
          <MenubarItem>
            <NavLink to="/convoso">
              <Icon name="phone" />
              <span>Convoso</span>
            </NavLink>
          </MenubarItem>
        )}

        {hasAccountPermission(user, AP.DATASETS) && (
          <MenubarItem>
            <NavLink to="/datasets/v1">
              <Icon name="database" />
              <span>Datasets v1</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.DATASETS_V2) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/datasets/v2">
              <Icon name="database" />
              <span>Datasets</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.LEADSCORE_PLUS) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/leadscore-plus">
              <Icon name="crosshairs" />
              <span>LeadScore+</span>
            </NavLink>
          </MenubarItem>
        )}

        {hasAccountPermission(user, AP.DATAFLOWS) && (
          <MenubarItem>
            <NavLink to="/dataflows">
              <Icon name="sitemap" />
              <span>Dataflows</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.FEEDS) || isAdmin) && (
          <MenubarItem>
            <a href={`${frontendUrl}/feeds`}>
              <Icon name="code branch" />
              <span>Feeds</span>
            </a>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.FILTERS) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/filters">
              <Icon name="filter" />
              <span>Filters</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.MODELS) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/models">
              <Icon name="compress" />
              <span>Models</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.PIPELINES) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/pipelines">
              <Icon name="code branch" />
              <span>Pipelines</span>
            </NavLink>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.REPORTING) || isAdmin) && (
          <MenubarItem open={location.pathname.startsWith('/reporting')}>
            <NavLink to="/reporting">
              <Icon name="pie chart" />
              <span>Reporting</span>
            </NavLink>

            <MenubarChildren>
              <NavLink to={`/reporting/dashboard`} className="item">
                <Icon name="table" />
                <span>Dashboard</span>
              </NavLink>
              <NavLink to={`/reporting/stats`} className="item">
                <Icon name="chart bar" />
                <span>Stats</span>
              </NavLink>
              {user?.active_account_id === 57 && (
                <NavLink to={`/reporting/agent-attribution`} className="item">
                  <Icon name="chart bar" />
                  <span>Agent Attribution</span>
                </NavLink>
              )}
            </MenubarChildren>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.VOICE) || isAdmin) && (
          <MenubarItem open={location.pathname.startsWith('/voice')}>
            <NavLink to="/voice">
              <Icon name="phone" />
              <span>Voice</span>
            </NavLink>

            <MenubarChildren>
              <NavLink to="/voice/configs">
                <Icon name="cog" />
                <span>Configs</span>
              </NavLink>

              <NavLink to="/voice/conversations">
                <Icon name="comment alternate" />
                <span>Conversations</span>
              </NavLink>

              <NavLink to="/voice/calls">
                <Icon name="table" />
                <span>Calls</span>
              </NavLink>
            </MenubarChildren>
          </MenubarItem>
        )}

        {(hasAccountPermission(user, AP.WEBHOOKS) || isAdmin) && (
          <MenubarItem>
            <NavLink to="/webhooks">
              <Icon name="rss" />
              <span>Webhooks</span>
            </NavLink>
          </MenubarItem>
        )}

        <MenubarSpacer />

        {billingEnabled && (
          <MenubarItem>
            <NavLink to="/account/billing/settings">
              <Icon name="money bill alternate outline" />
              <span>
                Billing
                {billingIssue && <Icon name="warning circle" color="red" style={{ marginLeft: 4 }} />}
              </span>
            </NavLink>
          </MenubarItem>
        )}

        <MenubarItem>
          <NavLink to="#" isActive={() => false} onClick={toggleIsCollapsed}>
            <Icon name={`chevron ${isCollapsed ? 'right' : 'left'}`} />
            <span>{isCollapsed ? 'Expand' : 'Collapse'}</span>
          </NavLink>
        </MenubarItem>
      </Nav>
    </NavContainer>
  );
};

export default Menubar;
