import { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { VoiceCallDetailRecord } from 'src/types';

const AudioContainer = styled.div`
  position: relative;
  audio {
    position: absolute;
    top: 0;
    right: 50px;
    z-index: 1;
    height: 100%;
  }
`;

const AudioPlayer = ({ call }: { call: VoiceCallDetailRecord }) => {
  const [isVisible, setIsVisible] = useState(false);

  if (!call.recordingUrl) return null;

  return (
    <AudioContainer>
      <Button icon onClick={() => setIsVisible(prev => !prev)}>
        <Icon name={isVisible ? 'close' : 'play'} />
      </Button>
      {isVisible && <audio autoPlay controls src={call.recordingUrl} />}
    </AudioContainer>
  );
};

export default AudioPlayer;
