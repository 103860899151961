import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Header, Icon, Popup, Tab } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useUpdateQualifaiConversationMutation } from 'src/api/qualifai-conversations';
import { useGetQualifaiDialogflowBotQuery } from 'src/api/qualifai-dialogflow';
import ApiMessage from 'src/components/ApiMessage';
import { DEFAULT_BOT_PROJECT_ID, newQualifaiConversationGenerativeAgent, QualifaiConversation } from 'src/types';
import TabGenerativeAgent from './TabGenerativeAgent';
import TabOpener from './TabOpener';

export type ValidationErrors = {
  name?: string;
  sharedOutputs?: Record<string, string>[];
  nodes?: Record<string, Record<string, string>>;
};

const getInitialFormdata = (conversation: QualifaiConversation): QualifaiConversation => {
  const next = _cloneDeep(conversation);

  // Set any missing defaults and/or apply data type conversions

  if (!next.fronter) {
    next.fronter = newQualifaiConversationGenerativeAgent();
  }

  if (!next.closer) {
    next.closer = newQualifaiConversationGenerativeAgent();
  }

  return next;
};

type Props = {
  conversation: QualifaiConversation;
};

const EditQualifaiConversationForm = ({ conversation }: Props) => {
  const { mutateAsync, isLoading: saveLoading } = useUpdateQualifaiConversationMutation();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [isValid, setIsValid] = useState(false);
  const [formdata, setFormdata] = useState<QualifaiConversation>(() => getInitialFormdata(conversation));
  const [saved, setSaved] = useState(true);
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: selectedBot } = useGetQualifaiDialogflowBotQuery(
    formdata.dialogflowProjectId || DEFAULT_BOT_PROJECT_ID
  );

  const conversationIntents = useMemo(
    () =>
      selectedBot?.intents
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(i => ({
          key: i.name,
          value: i.name,
          text: i.name,
        })) || [],
    [selectedBot?.intents]
  );

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  const validate = useCallback(
    (c: QualifaiConversation) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      // Action and next node validation for intents
      if (c.sharedOutputs.length) {
        const sharedOutputErrors: Record<string, string>[] = [];
        c.sharedOutputs.forEach((n, i) => {
          if (!n.action) {
            sharedOutputErrors[i] = { action: 'Action is required' };
          } else {
            if (n.action === 'continue' && !n.nodeId) {
              sharedOutputErrors[i] = { nodeId: 'Next Node is required' };
            }
          }

          if (n.contextName && !conversationIntents.find(c => c.value === n.contextName)) {
            sharedOutputErrors[i] = {
              ...sharedOutputErrors[i],
              intent: `The selected bot does not have '${n.contextName}' intent`,
            };
          }
        });
        if (sharedOutputErrors.length) {
          errors.sharedOutputs = sharedOutputErrors;
        }
      }

      // Action and next node validation for questions
      if (!isEmpty(c.nodes)) {
        const nodeErrors: Record<string, Record<string, string>> = {};
        Object.entries(c.nodes).forEach(([nodeId, node]) => {
          if (!node.action) {
            nodeErrors[nodeId] = { action: 'Action is required' };
          } else {
            if (node.action === 'continue' && !node.nextNodeId) {
              nodeErrors[nodeId] = { nextNodeId: 'Next Node is required' };
            }
          }
        });
        if (!isEmpty(nodeErrors)) {
          errors.nodes = nodeErrors;
        }
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [setIsValid, viewErrors, conversationIntents]
  );

  useEffect(() => {
    validate(formdata);
  }, [formdata, validate]);

  const saveConversation = useCallback(
    async (c: QualifaiConversation, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      try {
        await mutateAsync(c);
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, saved]
  );

  const onChange = useCallback(
    (_, { checked, name, value }) => {
      setFormdata(prev => {
        let v = typeof checked !== 'undefined' ? checked : value;
        if (name.includes('audioDuration')) {
          v = Number(v);
          if (Number.isNaN(v)) {
            v = 0;
          }
        }

        const next = _cloneDeep(prev);
        _set(next, name, v);

        validate(next);

        return next;
      });
      setSaved(false);
    },
    [validate]
  );

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConversation(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <div>
        <Header>General</Header>

        <Form.Group>
          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

          <Form.Field>
            <label>Enabled</label>
            <Form.Checkbox toggle name="enabled" checked={formdata.enabled} onChange={onChange} />
          </Form.Field>
        </Form.Group>

        <Divider />

        <Tab
          menu={{ secondary: true, pointing: true }}
          renderActiveOnly
          activeIndex={activeIndex}
          onTabChange={(_, { activeIndex }) => setActiveIndex(Number(activeIndex))}
          panes={[
            {
              menuItem: 'Opener',
              render: () => (
                <TabOpener
                  errors={errors}
                  formdata={formdata}
                  onChange={onChange}
                  setFormdata={setFormdata}
                  setSaved={setSaved}
                />
              ),
            },
            {
              menuItem: 'Fronter',
              render: () => (
                <TabGenerativeAgent
                  formdataKey="fronter"
                  errors={errors}
                  formdata={formdata}
                  onChange={onChange}
                  setFormdata={setFormdata}
                  setSaved={setSaved}
                />
              ),
            },
            // {
            //   menuItem: 'Closer',
            //   render: () => (
            //     <TabGenerativeAgent
            //       formdataKey="closer"
            //       errors={errors}
            //       formdata={formdata}
            //       onChange={onChange}
            //       setFormdata={setFormdata}
            //       setSaved={setSaved}
            //     />
            //   ),
            // },
          ]}
        />
      </div>
    </Form>
  );
};

export default EditQualifaiConversationForm;
