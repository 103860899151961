import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';

import { useListVoiceCallsQuery, VoiceCallsFilters } from 'src/api/voice-calls';
import { RenderProps } from 'src/components/PaginatedTable';
import { Note } from 'src/styles';
import theme from 'src/styles/theme';
import { msToHourMinSec } from 'src/utils';
import AudioPlayer from './AudioPlayer';
import VoiceCallDetailsModal from './VoiceCallDetailsModal';

const { REACT_APP_BETA_URL: betaURL } = process.env;

const formatToMinSec = (ms: number) => {
  const [, m, s] = msToHourMinSec(ms);
  return m > 0 ? `${m} min ${s} sec` : `${s} sec`;
};

type Props = RenderProps & { filters: VoiceCallsFilters };

const ListVoiceCallsBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const { data: calls, isLoading } = useListVoiceCallsQuery({
    limit,
    offset: limit * (currentPage - 1),
    ...filters,
  });

  useEffect(() => {
    let count = 1;
    if (typeof calls !== 'undefined') {
      count = Math.ceil(calls.total / limit);
    }
    setPageCount(count);
  }, [calls, limit, setPageCount]);

  if (isLoading) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9} style={{ textAlign: 'center' }}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  if (calls?.count === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9}>No Calls Found</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {calls?.data.map(c => (
        <Table.Row key={c.id}>
          <Table.Cell>
            <strong>{c.phone}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>{c.id}</span>
          </Table.Cell>
          <Table.Cell>
            <span title={c.createdAt}>
              {formatDistanceToNow(new Date(c.createdAt), { addSuffix: true, includeSeconds: true })}
            </span>
          </Table.Cell>
          <Table.Cell>{c.direction}</Table.Cell>
          <Table.Cell>{formatToMinSec(c.durationMs)}</Table.Cell>
          <Table.Cell>{c.voiceConfigName}</Table.Cell>
          <Table.Cell>{c.conversationName || <Note>None</Note>}</Table.Cell>
          <Table.Cell>{c.disposition || <Note>None</Note>}</Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            <AudioPlayer call={c} />
          </Table.Cell>
          <Table.Cell collapsing>
            <VoiceCallDetailsModal call={c} />
            <a href={`${betaURL}/api/voice/recordings/${c.recordingId}/download`}>
              <Button title="Download" icon>
                <Icon name="download" />
              </Button>
            </a>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListVoiceCallsBody;
