import { capitalize, isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Header, Icon, Label, Popup } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import { useListBlacklistsQuery } from 'src/api/blacklists';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListAllQualifaiConversationsQuery } from 'src/api/qualifai-conversations';
import { useSaveVoiceConfigMutation } from 'src/api/voice-configs';
import { useListWebhookConfigsQuery } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';
import PublicFileUploader from 'src/components/ImageUploader';
import ScheduleTableForm, { getInitialScheduleData } from 'src/pages/user/models/schedule/ScheduleTableForm';
import { Note, Row } from 'src/styles';
import {
  HumanDetectionEndpoints,
  HumanDetectionSpeechModels,
  Schedule,
  VoiceConfig,
  VoiceConfigTemplates,
  VoiceProviders,
  VoiceRouterOptions,
} from 'src/types';
import { cleanPhone } from 'src/utils';
import { Layout } from './style';

const betaURL = process.env.REACT_APP_BETA_URL;

type ValidationErrors = {
  // General
  name?: string;
  provider?: string;
  // Messaging
  messagingBlacklistIDs?: string;
  messagingDncBlacklistID?: string;
  // Inbound
  inboundConversationId?: string;
  inboundIvrAudioUrl?: string;
  inboundTemplate?: string;
  inboundTransferTo?: string;
  // Outbound
  outboundConversationId?: string;
  outboundIvrAudioUrl?: string;
  outboundTemplate?: string;
  outboundTransferTo?: string;
  outboundRingTimeout?: string;
};

const getInitialFormdata = (config: VoiceConfig): VoiceConfig => {
  const next = _cloneDeep(config);

  // Set any missing defaults and/or apply data type conversions
  if (next.schedule && !next.schedule.timezone) {
    next.schedule.timezone = 'America/New_York';
  }

  return next;
};

type Props = {
  config: VoiceConfig;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
};

const isValidSip = (v: any): boolean => {
  if (typeof v !== 'string') return false;
  if (!v.startsWith('sip:')) return false;
  if (v.indexOf('@') === -1) return false;

  const parts = v.split('@');
  if (parts.length !== 2) return false;

  // TODO: Validate this is a valid domain or IP address
  if (parts[1].length < 3) return false;

  return true;
};

const EditVoiceConfigForm = ({ config, isValid, setIsValid }: Props) => {
  const { data: user } = useGetUserProfileQuery();
  const { data: apiTokens } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });
  const { data: blacklists, isLoading: blacklistsLoading } = useListBlacklistsQuery({ limit: 500, offset: 0 });
  const { data: conversations, isLoading: conversationsLoading } = useListAllQualifaiConversationsQuery({
    limit: 500,
    offset: 0,
  });
  const { data: webhookConfigs, isLoading: webhookConfigsLoading } = useListWebhookConfigsQuery({
    limit: 100,
    offset: 0,
  });
  const { mutateAsync, isLoading: saveLoading } = useSaveVoiceConfigMutation();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<VoiceConfig>(() => getInitialFormdata(config));
  const [saved, setSaved] = useState(true);
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);

  const isAdmin = user?.role === 'admin';

  const activeAccount = user?.active_account;

  const showRouterOutbound =
    activeAccount?.commio && activeAccount?.commio.account_id && activeAccount?.commio.account_token;

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  const validate = useCallback(
    (c: VoiceConfig) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      // Ring Timeout
      if (c.outbound.ring_timeout < 0) {
        errors.outboundRingTimeout = 'Ring timeout cannot be negative';
      }

      // Messaging
      if (!c.messaging?.blacklist_ids || c.messaging?.blacklist_ids.length === 0) {
        errors.messagingBlacklistIDs = 'Blacklist(s) are required';
      }
      if (!c.messaging?.dnc_blacklist_id) {
        errors.messagingDncBlacklistID = 'Blacklist is required';
      }

      // Inbound
      if (!c.inbound.template) {
        errors.inboundTemplate = 'Template is required';
      }

      if (c.inbound.template === 'conversation' && !c.inbound.conversation_id) {
        errors.inboundConversationId = 'Conversation is required';
      }

      if (c.inbound.template === 'ivr' && !c.inbound.ivr_audio_url) {
        errors.inboundIvrAudioUrl = 'IVR Audio URL is required';
      }

      if (c.inbound.template !== 'conversation' && !c.inbound.transfer_to.trim()) {
        errors.inboundTransferTo = 'Transfer To is required';
      }

      if (
        c.provider === 'twilio' &&
        (c.inbound.transfer_to.includes('sip.twilio.com') || c.inbound.transfer_to.includes('ptsn.twilio.com'))
      ) {
        errors.inboundTransferTo =
          'Unable to transfer to a Twilio SIP Domain or Elastic SIP Endpoint owned by a different Twilio account';
      }

      if (
        (c.inbound.transfer_to.includes('sip') || c.inbound.transfer_to.includes('@')) &&
        !isValidSip(c.inbound.transfer_to)
      ) {
        errors.inboundTransferTo = 'Invalid SIP address';
      }

      // Outbound
      if (!c.outbound.template) {
        errors.outboundTemplate = 'Template is required';
      }

      if (c.outbound.template === 'conversation' && !c.outbound.conversation_id) {
        errors.outboundConversationId = 'Conversation is required';
      }

      if (c.outbound.template === 'ivr' && !c.outbound.ivr_audio_url) {
        errors.outboundIvrAudioUrl = 'IVR Audio URL is required';
      }

      if (c.outbound.template !== 'conversation' && !c.outbound.transfer_to.trim()) {
        errors.outboundTransferTo = 'Transfer To is required';
      }

      if (
        c.provider === 'twilio' &&
        (c.outbound.transfer_to.includes('sip.twilio.com') || c.outbound.transfer_to.includes('ptsn.twilio.com'))
      ) {
        errors.outboundTransferTo =
          'Unable to transfer to a Twilio SIP Domain or Elastic SIP Endpoint owned by a different Twilio account';
      }

      if (
        (c.outbound.transfer_to.includes('sip') || c.outbound.transfer_to.includes('@')) &&
        !isValidSip(c.outbound.transfer_to)
      ) {
        errors.outboundTransferTo = 'Invalid SIP address';
      }

      // Admin
      if (isAdmin) {
        if (!c.provider) {
          errors.provider = 'Provider is required';
        }
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [isAdmin, setIsValid, viewErrors]
  );

  const saveConfig = useCallback(
    async (config: VoiceConfig, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      try {
        await mutateAsync({ config });
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, saved]
  );

  useEffect(() => {
    validate(formdata);
  }, [formdata, validate]);

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      // Convert numeric values to numbers
      let v = typeof checked !== 'undefined' ? checked : value;
      if (['inbound.ivr_blacklist_id', 'outbound.rate_limit_value', 'outbound.ring_timeout'].includes(name)) {
        v = Number(v);
        if (Number.isNaN(v)) {
          v = 0;
        }
      }

      const next = _cloneDeep(prev);
      _set(next, name, v);

      if (name === 'outbound.predictive_rate_limit_enabled' && v === true && !prev.outbound.rate_limit_enabled) {
        _set(next, 'outbound.rate_limit_enabled', true);
      }

      return next;
    });
    setSaved(false);
  }, []);

  const onBlurPhone = useCallback(({ currentTarget: { name, value } }: FocusEvent<HTMLInputElement, Element>) => {
    setFormdata(prev => {
      // Clean and Format Phone Numbers
      let v = cleanPhone(value);
      if (v.length > 0 && v.indexOf('sip:') === -1 && v.indexOf('+1') !== 0) {
        v = `+1${v}`;
      }

      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });
    setSaved(false);
  }, []);

  const onChangeSchedule = useCallback((s: Schedule) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(next, 'schedule', s);
      return next;
    });
    setSaved(false);
  }, []);

  const voiceRouters = useMemo(() => {
    return VoiceRouterOptions.filter(o => o.providers.includes(formdata.provider || 'bandwidth')).map(p => ({
      ...p,
      key: p.value,
    }));
  }, [formdata.provider]);

  useEffect(() => {
    if (!formdata.router_outbound) return;
    if (!voiceRouters.map(m => m.value).includes(formdata.router_outbound)) {
      onChange(null, { name: 'router_outbound', value: '' });
    }
  }, [formdata.router_outbound, onChange, voiceRouters]);

  const apiToken = useMemo(() => {
    let token = '{api_token}';
    if (apiTokens && apiTokens.data.length > 0) {
      token = apiTokens.data[0].token;
    }
    return token;
  }, [apiTokens]);

  const getWebhookURL = useCallback(
    (webhookID: string | undefined) => {
      if (!webhookID) return '';

      const webhook = webhookConfigs?.data.find(c => c.id === webhookID);
      if (!webhook) return '';

      return `${betaURL}/api/call-transfer/${webhook.id}?token=${apiToken}`;
    },
    [apiToken, webhookConfigs?.data]
  );

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  let defaultLimit = 60;
  if (activeAccount?.voice?.outbound_limit_max) {
    defaultLimit = activeAccount?.voice.outbound_limit_max;
  }
  if (activeAccount?.voice?.outbound_limit) {
    defaultLimit = activeAccount?.voice.outbound_limit;
  }

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConfig(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>General</Header>

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

          <ScheduleTableForm
            renderTemplates
            renderTimezone
            schedule={formdata.schedule || getInitialScheduleData()}
            setSchedule={onChangeSchedule}
          />

          <Divider />

          <Header>Messaging</Header>
          <Note>
            The default messaging configuration is only set up to handle blacklisting customers when they send a message
            with a negative sentiment. In general, we will avoid sending any response back to the customer unless we
            have just added them to the blacklist.
          </Note>

          <Form.Select
            label="Check Blacklist(s)"
            options={
              blacklists?.data.map(b => ({
                key: b.id,
                value: b.id,
                text: b.name,
              })) || []
            }
            multiple
            clearable
            loading={blacklistsLoading}
            value={formdata.messaging?.blacklist_ids || []}
            name="messaging.blacklist_ids"
            onChange={onChange}
            error={errors.messagingBlacklistIDs}
          />
          <Note>
            Every time we receive a new message from the customer, we will first check these blacklist(s) to see if they
            are already blacklisted. If they are, no further messages will be sent.
          </Note>

          <Form.Select
            label="Blacklist on Negative Sentiment"
            options={
              blacklists?.data
                .filter(b => b.id > 3)
                .map(b => ({
                  key: b.id,
                  value: b.id,
                  text: b.name,
                })) || []
            }
            clearable
            loading={blacklistsLoading}
            value={formdata.messaging?.dnc_blacklist_id || ''}
            name="messaging.dnc_blacklist_id"
            onChange={onChange}
            error={errors.messagingDncBlacklistID}
          />
          <Note>
            If we detect a negative sentiment, such as stop, no, f*** you, etc. we will add the customer to the selected
            blacklist and respond with the following message:
            <blockquote>You have been added to our Do Not Call list.</blockquote>
          </Note>
          <Note>
            Unless the account has an approved A2P 10DLC profile, we are unable to send any messages at all. In which
            case, the above message would not be sent after the customer is added to the blacklist.
          </Note>
        </div>

        <div style={{ gridArea: 'inbound' }}>
          <Header>
            Inbound
            {formdata.inbound.enable_call_recordings && (
              <Label color="red" title="Call Recording Enabled">
                <Icon name="circle" />
                REC
              </Label>
            )}
          </Header>
          <Note>
            Inbound calls will only be accepted during the specified schedule. If a call is received outside of the
            scheduled hours, it will be sent directly to voicemail.
          </Note>

          {isAdmin && (
            <Form.Checkbox
              toggle
              name="inbound.enable_call_recordings"
              label="Enable Call Recordings?"
              checked={formdata.inbound.enable_call_recordings}
              onChange={onChange}
            />
          )}

          <Form.Select
            label="Check Blacklist(s)?"
            options={
              blacklists?.data.map(b => ({
                key: b.id,
                value: b.id,
                text: b.name,
              })) || []
            }
            multiple
            clearable
            loading={blacklistsLoading}
            value={formdata.inbound.blacklist_ids || []}
            name="inbound.blacklist_ids"
            onChange={onChange}
          />
          <Note>
            If the inbound phone number is blacklisted, the following message will be played and then the call will hang
            up:
            <blockquote>You are on our block list. Goodbye.</blockquote>
          </Note>

          <Form.Select
            label="Call Flow Template"
            value={formdata.inbound.template}
            name="inbound.template"
            onChange={onChange}
            error={errors.inboundTemplate}
            clearable
            options={VoiceConfigTemplates.map(t => ({ ...t, key: t.value }))}
          />

          {formdata.inbound.template === 'conversation' && (
            <>
              <Form.Select
                label="Conversation"
                value={formdata.inbound.conversation_id}
                name="inbound.conversation_id"
                onChange={onChange}
                loading={conversationsLoading}
                error={errors.inboundConversationId}
                clearable
                placeholder={conversations?.data.length === 0 ? 'No conversations found' : undefined}
                options={(conversations?.data || []).map(c => ({ key: c.id, value: c.id, text: c.name }))}
              />
              <Note>The selected conversation will be started immediately.</Note>
            </>
          )}

          {formdata.inbound.template === 'ivr' && (
            <>
              <Form.Input
                label="IVR Audio URL"
                placeholder=""
                value={formdata.inbound.ivr_audio_url || ''}
                name="inbound.ivr_audio_url"
                onChange={onChange}
                error={errors.inboundIvrAudioUrl}
              />
              <PublicFileUploader
                fileType="audio"
                name="inbound.ivr_audio_url"
                onChange={onChange}
                value={formdata.inbound.ivr_audio_url}
              />
              <Note>
                This audio will be played immediately when a customer calls into this config. Upon pressing any key they
                will be transferred to the phone number in the "Transfer To" field below.
              </Note>
            </>
          )}

          <Form.Select
            label={formdata.inbound.template === 'ivr' ? 'Blacklist on Press 9' : 'Blacklist on DNC Intent'}
            options={
              blacklists?.data
                .filter(b => b.id > 3)
                .map(b => ({
                  key: b.id,
                  value: b.id,
                  text: b.name,
                })) || []
            }
            // multiple
            clearable
            loading={blacklistsLoading}
            value={formdata.inbound.ivr_blacklist_id || ''}
            name="inbound.ivr_blacklist_id"
            onChange={onChange}
          />
          <Note>
            If a blacklist is selected and the{' '}
            {formdata.inbound.template === 'conversation' ? 'dnc intent is triggered' : 'prospect presses 9'}, the
            following message will be played and the customer will be added to the selected list.
            <blockquote>You have been added to our Do Not Call list. Goodbye.</blockquote>
          </Note>

          <Form.Select
            clearable
            placeholder="Select a webhook"
            label="Call Transfer Webhook"
            value={formdata.inbound.call_transfer_webhook_id || ''}
            name="inbound.call_transfer_webhook_id"
            onChange={onChange}
            options={webhookConfigs?.data.map(c => ({ key: c.id, value: c.id, text: c.name })) || []}
            loading={webhookConfigsLoading}
          />
          <Form.Input
            label="Call Transfer URL"
            placeholder={getWebhookURL(formdata.inbound.call_transfer_webhook_id)}
            value={formdata.inbound.call_transfer_url || ''}
            name="inbound.call_transfer_url"
            onChange={onChange}
          />
          <Note>Right before the call is transferred to the number below, this API request will be fired.</Note>

          <Form.Input
            label="Transfer To"
            placeholder=""
            value={formdata.inbound.transfer_to || ''}
            name="inbound.transfer_to"
            onBlur={onBlurPhone}
            onChange={onChange}
            error={errors.inboundTransferTo}
          />
          {formdata.inbound.template === 'conversation' && (
            <Note>If the caller makes it through the prompts in the bot they will be transferred to this number.</Note>
          )}

          <Form.Input
            label="Voicemail Greeting Audio URL"
            placeholder="Default Greeting"
            value={formdata.inbound.vm_greeting_audio_url || ''}
            name="inbound.vm_greeting_audio_url"
            onChange={onChange}
          />
          <PublicFileUploader
            fileType="audio"
            name="inbound.vm_greeting_audio_url"
            onChange={onChange}
            value={formdata.inbound.vm_greeting_audio_url}
          />
          <Note>
            If no audio recording is provided, the following default greeting will be played when the call is answered:
            <blockquote>
              You have reached us after hours. To leave a voicemail press any key or remain on the line. Otherwise, hang
              up and try again later.
            </blockquote>
          </Note>
          <Note>
            The customer must then press a key or wait for the greeting to finish. After the either of these actions,
            the following message will be played to the user right before they are allowed to leave their voicemail:
            <blockquote>Please leave your message after the tone. Press the pound key when finished.</blockquote>
          </Note>
        </div>

        <div style={{ gridArea: 'outbound' }}>
          <Header>
            Outbound
            {formdata.outbound.enable_call_recordings && (
              <Label color="red" title="Call Recording Enabled">
                <Icon name="circle" />
                REC
              </Label>
            )}
          </Header>
          <Note>Outbound calls can only be initiated during the specified schedule.</Note>
          <Note>All outbound calls currently use human detection.</Note>

          {isAdmin && (
            <Form.Checkbox
              toggle
              name="outbound.enable_call_recordings"
              label="Enable Call Recordings?"
              checked={formdata.outbound.enable_call_recordings}
              onChange={onChange}
            />
          )}

          <Form.Input
            label="Ring Timeout"
            placeholder={formdata.outbound.vm_message_enabled ? '300' : '25'}
            type="number"
            name="outbound.ring_timeout"
            value={formdata.outbound.ring_timeout || ''}
            onChange={onChange}
            error={errors.outboundRingTimeout}
          />

          <Row style={{ gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
            <Header as="h4" style={{ marginBottom: 0 }}>
              Rate Limit
            </Header>

            <Form.Checkbox
              toggle
              label="&nbsp;"
              checked={formdata.outbound.rate_limit_enabled}
              name="outbound.rate_limit_enabled"
              onChange={onChange}
            />
          </Row>

          <Form.Group style={{ opacity: !formdata.outbound.rate_limit_enabled ? 0.25 : undefined }}>
            <Form.Input
              label="Outbound calls (per minute)"
              placeholder={defaultLimit}
              value={formdata.outbound.rate_limit_value || ''}
              name="outbound.rate_limit_value"
              onChange={onChange}
              type="number"
            />
          </Form.Group>

          {(isAdmin || formdata.outbound.predictive_rate_limit_visible) && (
            <div style={{ opacity: !formdata.outbound.rate_limit_enabled ? 0.25 : undefined }}>
              <Row style={{ height: 22, gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
                <Header as="h4" style={{ marginBottom: 0 }}>
                  Predictive Rate Limit
                </Header>

                <Form.Checkbox
                  toggle
                  label="&nbsp;"
                  checked={formdata.outbound.predictive_rate_limit_enabled}
                  name="outbound.predictive_rate_limit_enabled"
                  onChange={onChange}
                />
              </Row>

              <Form.Group style={{ opacity: formdata.outbound.predictive_rate_limit_enabled ? 1 : 0.25 }}>
                <Form.Input
                  label="Vici Campaign ID(s)"
                  placeholder="All"
                  value={formdata.outbound.predictive_rate_limit_campaign_ids || ''}
                  name="outbound.predictive_rate_limit_campaign_ids"
                  onChange={onChange}
                />

                <Form.Input
                  readOnly
                  label="Current Predicted Call Limit"
                  placeholder={activeAccount?.voice?.outbound_limit || '60'}
                  value={formdata.outbound.predictive_rate_limit_value || ''}
                  // name="outbound.predictive_rate_limit_value"
                  // onChange={onChange}
                  type="number"
                />
              </Form.Group>
            </div>
          )}

          <Form.Select
            label="Check Blacklist(s)?"
            options={
              blacklists?.data.map(b => ({
                key: b.id,
                value: b.id,
                text: b.name,
              })) || []
            }
            multiple
            clearable
            loading={blacklistsLoading}
            value={formdata.outbound.blacklist_ids || []}
            name="outbound.blacklist_ids"
            onChange={onChange}
          />
          <Note>
            Before placing the outbound call, we will check the selected blacklists and prevent the dial from happening
            if the phone number is found in any of the lists.
          </Note>

          <Form.Select
            label="Call Flow Template"
            value={formdata.outbound.template}
            name="outbound.template"
            onChange={onChange}
            error={errors.outboundTemplate}
            clearable
            options={VoiceConfigTemplates.map(t => ({ ...t, key: t.value }))}
          />

          {formdata.outbound.template === 'conversation' && (
            <>
              <Form.Select
                label="Conversation"
                value={formdata.outbound.conversation_id}
                name="outbound.conversation_id"
                onChange={onChange}
                loading={conversationsLoading}
                error={errors.outboundConversationId}
                clearable
                placeholder={conversations?.data.length === 0 ? 'No conversations found' : undefined}
                options={(conversations?.data || []).map(c => ({ key: c.id, value: c.id, text: c.name }))}
              />
              <Note>After detecting a human on the call, the conversation will be started.</Note>
            </>
          )}

          {formdata.outbound.template === 'ivr' && (
            <>
              <Form.Input
                label="IVR Audio URL"
                placeholder=""
                value={formdata.outbound.ivr_audio_url || ''}
                name="outbound.ivr_audio_url"
                onChange={onChange}
                error={errors.outboundIvrAudioUrl}
              />
              <PublicFileUploader
                fileType="audio"
                name="outbound.ivr_audio_url"
                onChange={onChange}
                value={formdata.outbound.ivr_audio_url}
              />
              <Note>
                This audio will be played immediately after a customer has been detected. Upon pressing any key they
                will be transferred to the phone number in the "Transfer To" field below.
              </Note>
            </>
          )}

          <Form.Select
            label={
              formdata.outbound.template === 'conversation' ? 'Blacklist on DNC Intent' : 'Blacklist on IVR Press 9'
            }
            options={
              blacklists?.data
                .filter(b => b.id > 3)
                .map(b => ({
                  key: b.id,
                  value: b.id,
                  text: b.name,
                })) || []
            }
            // multiple
            clearable
            loading={blacklistsLoading}
            value={formdata.outbound.ivr_blacklist_id || ''}
            name="outbound.ivr_blacklist_id"
            onChange={onChange}
          />
          <Note>
            If a blacklist is selected and the{' '}
            {formdata.outbound.template === 'conversation' ? 'dnc intent is triggered' : 'prospect presses 9'}, the
            following message will be played and the customer will be added to the selected list.
            <blockquote>You have been added to our Do Not Call list. Goodbye.</blockquote>
          </Note>

          <Form.Select
            clearable
            placeholder="Select a webhook"
            label="Call Transfer Webhook"
            value={formdata.outbound.call_transfer_webhook_id || ''}
            name="outbound.call_transfer_webhook_id"
            onChange={onChange}
            options={webhookConfigs?.data.map(c => ({ key: c.id, value: c.id, text: c.name })) || []}
            loading={webhookConfigsLoading}
          />
          <Form.Input
            label="Call Transfer URL"
            placeholder={getWebhookURL(formdata.outbound.call_transfer_webhook_id)}
            value={formdata.outbound.call_transfer_url || ''}
            name="outbound.call_transfer_url"
            onChange={onChange}
          />
          <Note>
            When a human is detected, right before they are transferred to the number below, this API request will be
            fired.
          </Note>

          <Form.Input
            label="Transfer To"
            placeholder=""
            value={formdata.outbound.transfer_to || ''}
            name="outbound.transfer_to"
            onBlur={onBlurPhone}
            onChange={onChange}
            error={errors.outboundTransferTo}
          />
          <Note>
            When a human is detected they will be transferred to this phone number. If a bot is selected above, the bot
            will decide if/when to transfer them based on their interaction with the bot.
          </Note>

          <Row style={{ gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
            <Header as="h4" style={{ marginBottom: 0 }}>
              Voicemail Message
            </Header>

            <Form.Checkbox
              toggle
              label="&nbsp;"
              checked={formdata.outbound.vm_message_enabled}
              name="outbound.vm_message_enabled"
              onChange={onChange}
            />
          </Row>

          <div style={{ opacity: formdata.outbound.vm_message_enabled ? undefined : 0.25 }}>
            <Form.Input
              label="Voicemail Message Audio URL"
              placeholder=""
              value={formdata.outbound.vm_message_audio_url || ''}
              name="outbound.vm_message_audio_url"
              onChange={onChange}
            />
            <PublicFileUploader
              fileType="audio"
              name="outbound.vm_message_audio_url"
              onChange={onChange}
              value={formdata.outbound.vm_message_audio_url}
            />
            <Note>
              When a machine is detected, this audio recording will be left as a voicemail message in the customer's
              inbox.
            </Note>
          </div>
        </div>

        {isAdmin && (
          <div style={{ gridArea: 'admin' }}>
            <Header>Admin</Header>

            <Row style={{ gap: '1rem', alignItems: 'stretch', margin: '0 0 1rem' }}>
              <div>
                <Row style={{ height: 22, gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
                  <Header as="h4" style={{ marginBottom: 0 }}>
                    General
                  </Header>
                </Row>

                <Form.Group>
                  <Form.Select
                    clearable
                    label="Provider"
                    placeholder="Bandwidth"
                    options={VoiceProviders.map(p => ({
                      ...p,
                      key: p,
                    }))}
                    value={formdata.provider || ''}
                    name="provider"
                    onChange={onChange}
                    error={errors.provider}
                  />

                  {showRouterOutbound && voiceRouters.length > 1 && (
                    <Form.Select
                      clearable
                      label="Outbound Router"
                      placeholder={`${capitalize(formdata.provider || 'bandwidth')}`}
                      options={voiceRouters}
                      value={formdata.router_outbound || ''}
                      name="router_outbound"
                      onChange={onChange}
                    />
                  )}
                </Form.Group>
              </div>

              <div style={{ borderLeft: '1px solid #eee', paddingLeft: '1rem' }}>
                <Row style={{ height: 22, gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
                  <Header as="h4" style={{ marginBottom: 0 }}>
                    Human Detection
                  </Header>

                  <Form.Checkbox
                    toggle
                    label="&nbsp;"
                    name="human_detection.enabled"
                    placeholder="Yes"
                    checked={formdata.human_detection?.enabled}
                    onChange={onChange}
                  />
                </Row>

                <Form.Group style={{ opacity: formdata.human_detection?.enabled ? 1 : 0.25 }}>
                  <Form.Select
                    clearable
                    label="Endpoint"
                    placeholder={HumanDetectionEndpoints[0]?.text || 'Prod'}
                    options={HumanDetectionEndpoints.map(e => ({ ...e, key: e.value }))}
                    value={formdata.human_detection.endpoint || ''}
                    name="human_detection.endpoint"
                    onChange={onChange}
                  />

                  <Form.Select
                    clearable
                    label="Speech Detection Model"
                    placeholder={HumanDetectionSpeechModels[0]?.text}
                    options={HumanDetectionSpeechModels.map(v => ({ ...v, key: v.value }))}
                    value={formdata.human_detection.speech_detection_model || ''}
                    name="human_detection.speech_detection_model"
                    onChange={onChange}
                  />

                  <Form.Select
                    label="Exclude Machines From Reporting"
                    placeholder="No"
                    options={[
                      { key: 'no', value: false, text: 'No' },
                      { key: 'yes', value: true, text: 'Yes' },
                    ]}
                    value={formdata.human_detection.exclude_machines_from_reporting || false}
                    name="human_detection.exclude_machines_from_reporting"
                    onChange={onChange}
                  />
                </Form.Group>
              </div>

              <div style={{ borderLeft: '1px solid #eee', paddingLeft: '1rem' }}>
                <Row style={{ height: 22, gap: '1rem', alignItems: 'center', margin: '0 0 1rem' }}>
                  <Header as="h4" style={{ marginBottom: 0 }}>
                    Predictive Rate Limit
                  </Header>
                </Row>

                <Form.Group>
                  <Form.Select
                    label="Visibility"
                    placeholder="Hidden"
                    options={[
                      { key: 'hidden', value: false, text: 'Hidden' },
                      { key: 'Visible', value: true, text: 'Visible' },
                    ]}
                    value={formdata.outbound.predictive_rate_limit_visible || false}
                    name="outbound.predictive_rate_limit_visible"
                    onChange={onChange}
                  />
                </Form.Group>
              </div>
            </Row>

            <Divider />
          </div>
        )}
      </Layout>
    </Form>
  );
};

export default EditVoiceConfigForm;
