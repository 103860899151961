import { useQuery } from 'react-query';

import { ApiResponse, http } from '../http-common';

export type ReportingDatascoreCallStat = {
  date: string;
  dials: number;
  connects: number;
  inbounds: number;
  transfers: number;
  vmdrops: number;
};

export type AccountReportingHourlyStat = {
  hour: string;
  dials: number;
  connects: number;
  inbounds: number;
  transfers: number;
  vmdrops: number;
};

export type ReportingCarrierGroupedStat = {
  duration: string;
  carrier: string;
  count: string;
};

type AccountReportingAllResponse = ApiResponse & {
  datascoreCalls: ReportingDatascoreCallStat[];
  groupedCarrierCalls: ReportingCarrierGroupedStat[];
};

export type GetAccountReportingParams = {
  startDate: string;
  endDate: string;
  voiceConfigId: string;
};

export const useGetAccountReportingQuery = (params: GetAccountReportingParams) => {
  return useQuery(['auth/account', 'reporting', params], async () => {
    const res = await http.get<AccountReportingAllResponse>(`/api/auth/account/reporting`, {
      params,
    });
    return res.data;
  });
};

type AccountReportingDatascoreResponse = ApiResponse & {
  datascoreCalls: ReportingDatascoreCallStat[];
};

export const useGetAccountReportingDatascoreQuery = (params: GetAccountReportingParams) => {
  return useQuery(['auth/account', 'reporting', 'datascore', params], async () => {
    const res = await http.get<AccountReportingDatascoreResponse>(`/api/auth/account/reporting/datascore`, {
      params,
    });
    return res.data;
  });
};

export type AccountReportingStat = {
  dials: number;
  connects: number;
  inbounds: number;
  transfers: number;
  humans: number;
  xfer_to_callcenter: number;
};

type AccountReportingStatsResponse = ApiResponse & {
  stat: AccountReportingStat;
};
export const useGetAccountReportingStatsQuery = (params: GetAccountReportingParams) => {
  return useQuery(['auth/account', 'reporting', 'reportingstats', params], async () => {
    const res = await http.get<AccountReportingStatsResponse>(`/api/auth/account/reporting/reportingstats`, {
      params,
    });
    return res.data.stat;
  });
};

type AccountReportingCarriersResponse = ApiResponse & {
  groupedCarrierCalls: ReportingCarrierGroupedStat[];
};

export const useGetAccountReportingCarriersQuery = (params: GetAccountReportingParams) => {
  return useQuery(['auth/account', 'reporting', 'carriers', params], async () => {
    const res = await http.get<AccountReportingCarriersResponse>(`/api/auth/account/reporting/carriers`, {
      params,
    });
    return res.data;
  });
};

type AccountReportingHourlyResponse = ApiResponse & {
  hourlyStats: AccountReportingHourlyStat[];
};

export const useGetAccountReportingHourlyQuery = (params: GetAccountReportingParams & { timezone: string }) => {
  return useQuery(['auth/account', 'reporting', 'hourly', params], async () => {
    const res = await http.get<AccountReportingHourlyResponse>(`/api/auth/account/reporting/hourly`, {
      params,
    });
    return res.data;
  });
};
