import { DropdownItemProps } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

export enum AccountingRevenueDriver {
  'calls' = 'calls',
  'connects' = 'connects',
  'minutes' = 'minutes',
  'flow-executions' = 'flow-executions',
}

export type AccountingAccountSettings = {
  id: number;
  account: Account | null;
  accountId: number;
  revenueDriver: AccountingRevenueDriver;
  revenueDriverRate: number;
};

export type AccountingLineItem = {
  enabled: boolean;
  name: string;
  amount: number;
  usage: AccountingUsageRecord[] | null;
};

export type AccountingUsageRecord = {
  category: string;
  count: string;
  countUnit: string;
  description: string;
  price: number;
  priceUnit: string;
  usage: string;
  usageUnit: string;
};

export type Account = {
  id: number;
  created_by: User | null;
  created: string | Date;
  enabled: boolean;
  onboarding_completed_at?: string;
  name: string;
  ownerEmail: string;
  // owner_id: number;
  // owner: User | null;
  test_phones: string | null;
  bigqueryDatasetId: string;
  billing: AccountBilling;
  currentBalanceConnectPro: number | null;
  currentBalanceLeadscorePlus: number | null;
  reporting: AccountReporting;
  hubspot: AccountHubspot;
  bigquery_table_access: AccountBigqueryTableAccess[] | null;
  model_access: ModelAccountAccess[] | null;
  akkioModelAccess: AkkioModelAccountAccess[] | null;
  user_access: AccountUserAccess[];
  permissions: AccountPermission[];
  sendgridProfile: SendgridProfile | null;
  bandwidth_profile: BandwidthProfile | null;
  telnyx_profile: TelnyxProfile | null;
  twilio_profile: TwilioProfile | null;
  twilio_customer_profile: TwilioCustomerProfile | null;
  business_profile: BusinessProfile | null;
  call_center: CallCenter | null;
  commio: AccountCommio | null;
  voice: AccountVoice | null;
  metadata: AccountMetadata;
  brooks: AccountBrooks | null;
};

export type AccountBilling = {
  enabled: boolean;
  alertEmails: string;
  stripeCustomerId: string;
  defaultPaymentMethod: string;
  connectPro: BillingConnectPro;
  leadscorePlus: BillingLeadscorePlus;
};

export const AccountBillingConfigs = ['leadscorePlus', 'connectPro'] as const;

export type AccountBillingConfig = typeof AccountBillingConfigs[number];

export type AccountBrooks = {
  censusProperties: string[] | null;
  phoneLookupProperties: string[] | null;
  cimaProperties: string[] | null;
};

export type AccountHubspot = {
  deal_id: string | null;
};

export type AccountReporting = {
  carriers: AccountReportingCarriers;
  datascore: AccountReportingDatascore;
};

export type AccountReportingCarriers = {
  enabled: boolean;
};

export type AccountReportingDatascore = {
  enabled: boolean;
  dials: boolean;
  connects: boolean;
  connects_pct: boolean;
  inbounds: boolean;
  transfers: boolean;
  transfers_pct: boolean;
  vmdrops: boolean;
};

export type AccountApiToken = {
  id: number;
  enabled: boolean;
  expires_at?: string | Date;
  name?: string;
  token: string;
};

export enum AccountPermission {
  'BANDWIDTH' = 'BANDWIDTH',
  'BLACKLISTS' = 'BLACKLISTS',
  'CONVOSO' = 'CONVOSO',
  'DATAFLOWS' = 'DATAFLOWS',
  'DATASETS' = 'DATASETS',
  'DATASETS_V2' = 'DATASETS_V2',
  'FEEDS' = 'FEEDS',
  'FILTERS' = 'FILTERS',
  'LEADSCORE_PLUS' = 'LEADSCORE_PLUS',
  'MODELS' = 'MODELS',
  'PIPELINES' = 'PIPELINES',
  'REPORTING' = 'REPORTING',
  'SENDGRID' = 'SENDGRID',
  'TELNYX' = 'TELNYX',
  'TWILIO' = 'TWILIO',
  'VOICE' = 'VOICE',
  'WEBHOOKS' = 'WEBHOOKS',
}

export type AccountBigqueryTableAccess = {
  table: BigqueryTable;
};

export const AccountUserAccessRoles = [
  { value: 'owner', text: 'Owner' },
  { value: 'billing', text: 'Billing' },
  { value: 'user', text: 'User' },
] as const;

export type AccountUserAccessRole = typeof AccountUserAccessRoles[number]['value'];

export type AccountUserAccess = {
  account_id: number;
  added: Date;
  enabled: boolean;
  roles: AccountUserAccessRole[];
  user_id: number;
  user?: User;
};

export type AccountWithAccounting = Account & {
  settings: AccountingAccountSettings | null;
  revenue: AccountingLineItem[];
  cogs: AccountingLineItem[];
  commissions: AccountingLineItem[];
};

export type AccountCommio = {
  account_id: string;
  account_token: string;
};

export type AccountVoice = {
  outbound_limit: number;
  outbound_limit_max: number;
  vad_timeout_ms: number;
};

export type AccountMetadata = {
  enabled: boolean;
  schedule: {
    every: number;
    frequency: string;
    days: number[];
  };
};

type AccountMetadataRebuildJobPayload = JobPayload & {
  accountId: string;
};

export type AccountMetadataRebuildJob = Job & {
  payload: AccountMetadataRebuildJobPayload;
};

export type AdminUserInvite = UserInvite & {
  id: number;
  role: string;
  account_roles: string[];
  created_at: string;
  created_by_id: number;
  created_by: User | null;
};

export type AkkioModel = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  enabled: boolean;
  name: string;
  config: AkkioModelConfig;
  accountAccess: AkkioModelAccountAccess[];
};

export type AkkioModelConfig = {
  requiresCensus: boolean;
  requiresPhoneLookup: boolean;
  requiresCimaCert: boolean;
  request: AkkioModelRequest;
  response: AkkioModelResponse;
};

export type AkkioModelRequest = {
  url: string;
  method: RequestMethod;
  headers: { [key: string]: string };
  timeout: number;
  bodyTemplate: string;
};

export type AkkioModelResponse = {
  outcomeFieldname: string;
  positiveValue: string;
  negativeValue: string;
};

export type AkkioModelAccountAccess = {
  accountId: number;
  account: Account | null;
  addedAt: string;
  addedById: number;
  addedBy: User | null;
  akkioModelId: number;
  akkioModel: AkkioModel;
  rate?: number;
};

export type BandwidthProfile = {
  id: number;
  account_id: number;
  account: Account | null;
  created_at: string | Date;
  sub_account_id: string | null;
  purchase_limit: number;
};

export type BandwidthAvailablePhone = {
  phone_number: string;
  friendly_name: string;
  description: string;
  locality: string | null;
  region: string | null;
  postal_code: string | null;
};

export enum BandwidthAvailablePhoneSearchType {
  'area-code' = 'area-code',
  'locality' = 'locality',
}

export type BandwidthPhone = {
  id: number;
  created_at: string;
  updated_at: string;
  phone_number: string;
  friendly_name: string;
  city: string | null;
  state: string | null;
  status: string;
  sub_account_id: string;
  location_id: string;
  voice_config_id: string;
  service_types: BandwidthPhoneServiceType[] | null;
  sms_enabled: boolean;
};

export const BandwidthPhoneServiceTypes = ['Messaging', 'Voice'] as const;

export type BandwidthPhoneServiceType = typeof BandwidthPhoneServiceTypes[number];

export type BandwidthPhonesRefetchJob = Job & {
  payload: BandwidthPhonesRefetchJobPayload;
};

export type BandwidthPhonesRefetchJobPayload = JobPayload & {
  accountId: string;
};

export type BandwidthApplication = {
  id: string;
  friendly_name: string;
  active: boolean;
  voice_url: string;
  voice_fallback_url: string;
  voice_method: string;
  status_callback: string;
  status_callback_method: string;
  created_at: string;
  updated_at: string;
};

export type BigqueryDataset = {
  id: number;
  created: string | Date;
  dataset_id: string;
  name: string;
  tables: BigqueryTable[];
};

export type BigqueryTableColumn = {
  name: string;
  type: string;
  required: boolean;
};

export type BigqueryFilter = {
  column: string;
  operator: string;
  value: string;
  id: string;
};

type BigqueryTableSyntheticData = {
  zipcodeEnabled: boolean;
};

export type BigqueryTableSettings = {
  name: string;
  filters: BigqueryFilter[] | null;
  requiredColumns: string[] | null;
  excludeCarriers: string[] | null;
  excludeConnects: boolean;
  excludeLandlines: boolean;
  checkBlacklist: number[] | null;
  deduplicate: boolean;
  deduplicateBy: string;
  deduplicateWithin: string;
  customColumns: { [key: string]: string } | null;
  feedId: number | null;
  fieldMappings: FieldMapping[] | null;
  syntheticData: BigqueryTableSyntheticData | null;
};

export type BigqueryTable = {
  id: number;
  bq_dataset_id: number;
  created: string | Date;
  table_id: string;
  name: string;
  time_column_name?: string;
  account_access?: AccountBigqueryTableAccess[];
  slug?: string;
  settings: BigqueryTableSettings;
  ingested_alltime: number;
  ingested_24h_success: number | null;
  ingested_24h_error: number | null;
  ingested_1h_success: number | null;
  ingested_1h_error: number | null;
  ingested_5m_success: number | null;
  ingested_5m_error: number | null;
  columns: BigqueryTableColumn[] | null;
};

export type BillingAutopay = {
  enabled: boolean;
  threshold: number;
  amount: number;
};

export type BillingDeposit = {
  amount: number;
  paid: boolean;
};

export type BillingBankAccount = {
  id: string;
  accountType: string;
  bankName: string;
  last4: string;
};

export type BillingCard = {
  id: string;
  name: string;
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export type BillingLeadscorePlus = {
  enabled: boolean;
  rates: BillingLeadscoreRates;
  autopay: BillingAutopay;
  deposit: BillingDeposit;
};

export type BillingLeadscoreRates = {
  census: number;
  phoneLookup: number;
  cima: number;
  prediction: number;
};

export type BillingLeadscorePlusTransaction = {
  id: number;
  createdAt: string;
  accountId: number;
  account: Account | null;
  amount: number;
  title: string;
  note: string | null;
  payload: any;
};

export const BillingPaymentIntentActions = ['add-funds-leadscorePlus', 'add-funds-connectPro', 'subscribe'] as const;

export type BillingPaymentIntentAction = typeof BillingPaymentIntentActions[number];

export enum PaymentMethodType {
  CARD = 'card',
  BANK_ACCOUNT = 'bank_account',
}

export type BillingPaymentMethod = {
  id: string;
  isDefault: boolean;
  type: PaymentMethodType;
  card?: BillingCard;
  bankAccount?: BillingBankAccount;
};

export type BillingSubscription = {
  isActive: boolean;
  day: number;
  cancelledAt: string | null;
};

export type BillingSubscriptionPayment = {
  id: number;
  account_id: number;
  account: Account | null;
  created_at: string;
  amount: number;
  status: string;
  payload: any;
};

export type BillingConnectPro = {
  enabled: boolean;
  rate: number;
  type: BillingConnectProType;
  minimumSeconds: number;
  intervalSeconds: number;
  autopay: BillingAutopay;
  deposit: BillingDeposit;
  subscription: BillingSubscription;
};

export type BillingConnectProTransaction = {
  id: number;
  createdAt: string;
  accountId: number;
  account: Account | null;
  amount: number;
  title: string;
  note: string | null;
  payload: any;
};

export const BillingConnectProTypes = [
  {
    value: 'connect',
    text: 'Per Connect',
    allowedPerVoiceConfig: true,
  },
  // {
  //   value: 'dial',
  //   text: 'Per Dial',
  //   allowedPerVoiceConfig: false,
  // },
  {
    value: 'minute',
    text: 'Per Minute',
    allowedPerVoiceConfig: true,
  },
  {
    value: 'monthly',
    text: 'Per Month',
    allowedPerVoiceConfig: false,
  },
  {
    value: 'vmdrop',
    text: 'Per Voicemail Drop',
    allowedPerVoiceConfig: true,
  },
] as const;

export type BillingConnectProType = typeof BillingConnectProTypes[number]['value'];

export type Blacklist = {
  id: number;
  created_at: string | Date;
  name: string;
  hash: string | null;
  account_access: BlacklistAccountAccess[] | null;
};

export type BlacklistAccountAccess = {
  blacklist_id: number;
  blacklist: Blacklist | null;
  account_id: number;
  account: Account | null;
  added_by_id: number;
  added_by: User | null;
  added_at: string | Date;
};

export type BusinessProfile = {
  ein_number: string;
  business_name: string;
  business_type: string;
  business_industry: string;
  website_url: string;
  address_1: string;
  address_2: string | null;
  city: string;
  state: string;
  zip: string;
  primary_contact: BusinessProfileContact;
  secondary_contact: BusinessProfileContact;
  // CNAM Registration
  cnam_display_name: string;
  // Voice Integrity
  use_case: string;
  avg_daily_calls: number;
  employee_count: number;
  notes: string;
};

export type BusinessProfileContact = {
  first_name: string;
  last_name: string;
  title: string;
  job_position: string;
  phone: string;
  email: string;
};

export type CallCenter = {
  id: number;
  inbound_phone_number: string;
  schedule_id: number;
  schedule: Schedule | null;
};

export enum ConvosoFileStatus {
  'Created' = 1,
  'Locked' = 2,
  'Released' = 3,
  'Uploaded' = 4,
}

export type ConvosoFile = {
  id: number;
  created: Date;
  filename: string;
  delimiter: Delimiter;
  status: ConvosoFileStatus;
  payload: ConvosoFilePayload | null;
  jobs: ConvosoFileJob[];
};

export type ConvosoFilePayload = {
  delimiter: Delimiter;
  field_mappings: string[];
  base_file_name: string;
  raw_gs_path: string;
  raw_num_rows: number;
  parsed_gs_path: string;
  parsed_num_rows: number;
};

export type ConvosoFileJob = Job & {
  payload: ConvosoFileJobPayload;
};

export type ConvosoFileJobPayload = JobPayload & {
  file_id: number;
  status: 'locked' | 'released';
};

export type ConvosoProfile = {
  id: number;
  created: Date;
  name: string;
  status: string;
  auth_token: string;
};

export type DataflowRevision = {
  id: number;
  dataflowId: number;
  created: string;
  event: string;
  payload: string;
};

export type Dataflow = DataflowSettings & {
  id: number;
  created: string;
};

export type DataflowJob = Job & {
  payload: DataflowJobPayload;
};

export type DataflowJobPayload = JobPayload & {
  dataflow_id: number;
  revision_id: number;
  outputs?: string[];
};

export enum Weekday {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

export const WeekdayShortNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export type WeekdayShortName = typeof WeekdayShortNames[number];

export const WeekdayLongNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export type WeekdayLongName = typeof WeekdayLongNames[number];

export type DataflowSettings = {
  name: string;
  schedule_enabled: boolean;
  schedule: {
    repetition: {
      type: string;
      interval: number;
      hour: Date;
      weekday?: WeekdayLongName;
      dayOfTheMonth?: string;
    };
    ends: {
      option: 'never' | 'on' | 'after';
      // value: number | Date | null;
      on?: Date;
      after?: number;
    };
  };
};

export type Dataset = {
  id: string;
  created_at: string;
  account_id: number;
  name: string;
  stats?: DatasetStats | null;
  // section 1
  custom_fields: DatasetField[] | null;
  field_mappings: FieldMapping[] | null;
  // section 2
  rules: DatasetRules;
  // section 3
  leadscore_config_id: string;
  synthetic_data: DatasetSyntheticData | null;
  feed_id: number;
  webhook_config_id: string;
};

export type DatasetStats = {
  ingested_24h: DatasetStatsCounts;
  ingested_1h: DatasetStatsCounts;
  ingested_5m: DatasetStatsCounts;
  queued: number;
};

export type DatasetStatsCounts = {
  success: number;
  error: number;
  required: number;
  invalid: number;
  filter: number;
  duplicate: number;
  cross_duplicate: number;
  blacklist: number;
  carrier: number;
  connect: number;
  landline: number;
  leadscore: number;
  billing: number;
};

export type DatasetField = {
  id: string;
  name: string;
  friendly_name: string;
  type: DatasetFieldType;
};

export const DatasetFieldTypeNames = ['STRING', 'NUMBER', 'BOOL', 'TIMESTAMP'] as const;

export type DatasetFieldType = typeof DatasetFieldTypeNames[number];

export type DatasetSyntheticData = {
  zipcode_enabled: boolean;
  city_state_enabled: boolean;
  firstname_lastname_enabled: boolean;
};

export type DatasetRules = {
  required_fields: string[] | null;
  deduplicate: DatasetRuleDedup | null;
  filters: RuleFilter[] | null;
  global_filters: GlobalRuleFilter[] | null;
  blacklists: number[] | null;
  exclude_carriers: string[] | null;
  exclude_connects: boolean;
  exclude_landlines: boolean;
};

export type GlobalRuleFilter = {
  id: string;
  filter_id: number;
};

export type RuleFilter = {
  id: string;
  field: string;
  operator: string;
  value: string;
};

export type DatasetRuleDedup = {
  enabled: boolean;
  allow_ext_dataset_trigger: boolean;
  on: string;
  within: number;
  datasets: string[];
};

export type DatasetUploadJob = Job & {
  type: JobType.DatasetUpload;
  payload: DatasetUploadJobPayload;
};

export type DatasetUploadJobPayload = JobPayload & {
  table_id: number;
  delimiter: Delimiter;
  field_mappings: string[];
  now: string;
  gcs_prefix: string;
  base_file_name: string;
  ext: string;
  raw_num_rows: number;
  raw_gs_path: string;
  success_count?: number;
  error_count?: number;
  column_count_error_count?: number;
  invalid_row_error_count?: number;
  required_fields_error_count?: number;
  filtered_fields_error_count?: number;
  duplicates_error_count?: number;
  blacklist_error_count?: number;
  excluded_carrier_error_count?: number;
  connects_error_count?: number;
  insert_failed_error_count?: number;
  landlines_error_count?: number;
};

export type DatasetsV2UploadJob = Job & {
  type: JobType.DatasetsV2Upload;
  payload: DatasetsV2UploadJobPayload;
};

export type DatasetsV2UploadJobPayload = JobPayload & {
  dataset_id: string;
  list_id?: string;
  delimiter: Delimiter;
  field_mappings: string[];
  now: string;
  gcs_prefix: string;
  base_file_name: string;
  ext: string;
  raw_num_rows: number;
  raw_gs_path: string;
  parsed_num_rows?: number;
  parsed_gs_path?: string;
};

export type BlacklistIngestJob = Job & {
  type: JobType.BlacklistIngest;
  payload: BlacklistIngestJobPayload;
};

export type BlacklistIngestJobPayload = JobPayload & {
  blacklist_id: number;
  delimiter: string;
  field_mappings: string[];
  now: string;
  gcs_prefix: string;
  base_file_name: string;
  ext: string;
  raw_num_rows: string;
  raw_gs_path: string;
  has_header: boolean;
  phones_added?: number;
  emails_added?: number;
  parsed_gs_path?: string;
  parsed_num_rows?: number;
};

export type Delimiter = 'Comma' | 'Pipe' | 'Tab';

export type DatasetList = {
  id: string;
  name: string;
  created_at: Date;
  dataset_id: string;
};

export const DE_METADATA_FIELDS = [
  'recent_call_date',
  'recent_call_status',
  'recent_call_direction',
  'recent_sip_response',
  'voicemail_drop_count',
  'voicemail_date',
  'recent_bot_disposition_id',
  'recent_bot_disposition_title',
  'recent_bot_campaign_name',
  'recent_dialer_disposition',
  'recent_dialer_created_date',
  'recent_dialer_entry_date',
  'carrier_name',
  'gender',
  'call_count',
  'raw_call_count',
  'transfer_to',
  'recent_hung_up_source',
];

export type EngagementCounts = {
  [key: string]: ThresholdCounts;
};

export type Feed = {
  id: number;
  user_id: number;
  user: User | null;
  account_id: number;
  account: Account | null;
  created: string | Date;
  lastused: string | Date;
  name: string;
  url: string;
  ingested_alltime: number;
};

export type Filter = {
  id: number;
  created: Date;
  name: string;
  type: FilterType;
  payload: any;
};

export enum FilterType {
  'Field Equals' = 1,
  'Field NOT Equals' = 2,
  'Field Starts With' = 3,
  'Field NOT Starts With' = 4,
}

export type HubspotDeal = {
  id: string;
  properties: HubspotDealProperties;
};

export type HubspotDealProperties = {
  dealname: string;
};

export type Job = {
  id: number;
  objectid?: number;
  stage: number; // 1, 2 or 3
  status: JobStatus;
  queued: string | null; // YYYY-MM-DD HH:ii:ss
  started: string | null; // YYYY-MM-DD HH:ii:ss
  stopped: string | null; // YYYY-MM-DD HH:ii:ss
  heartbeat: string | null; // YYYY-MM-DD HH:ii:ss
  progress1: number;
  progress2: number;
  progress3: number;
  payload: JobPayload;
  type: JobType;
};

export type JobPayload = {
  error?: string;
  message?: string;
};

export enum JobStatus {
  'Pending' = 1,
  'Processing' = 2,
  'Ready' = 3,
  'Stopping' = 4,
  'Error' = 5,
  'Completed' = 6,
  'Paused' = 7,
  'Cancelled' = 8,
  'Deleted' = 9,
  'Processing Externally' = 10,
  'Requires Payment' = 11,
  'Preprocessing' = 12,
}

export enum JobType {
  'DatasetUpload' = 1,
  'Append' = 2,
  'Export' = 3,
  'Render' = 4,
  'Import Model' = 5,
  'Ingest File' = 6,
  'Ingest Dataset' = 7,
  'Convoso Upload File' = 8,
  'Predict' = 9,
  'Dataflow' = 10,
  'Pipeline' = 11,
  'TwilioIncomingPhonesRefetch' = 12,
  'TwilioIncomingPhonesReassignTrustProfiles' = 13,
  'TwilioCallQueueCancelAll' = 14,
  'ReassignTwilioVoiceConfig' = 15,
  'DatasetDelete' = 16,
  'AgentaiCampaignsRefetch' = 17,
  'DatasetsV2Upload' = 18,
  'DatasetsV2Delete' = 19,
  'DatasetsV2ListDelete' = 20,
  'BlacklistIngest' = 21,
  'TelnyxPhonesRefetch' = 22,
  'TelnyxPhonesReassignVoiceConfig' = 23,
  'BandwidthPhonesRefetch' = 24,
  'BandwidthPhonesReassignVoiceConfig' = 25,
  'AccountMetadataRebuild' = 26,
  'BandwidthPhonesPurchaseByPostalCodes' = 27,
  'AgentaiCallsRecrawlAccount' = 28,
}

export const LEAD_SCORE_ACCOUNT_IDS: { [k: string]: number } = {
  Genie_Residents: 1,
  AlleviateTax: 57,
  Onehealthdirect: 68,
  ExpoHome: 74,
  ChasingNectarMedia: 84,
  Spark: 93,
  PrydeRemodeling: 101,
};

export const LeadscoreDefaultRates: BillingLeadscoreRates = {
  census: 0.0005,
  phoneLookup: 0.03,
  cima: 0.01,
  prediction: 0.003,
} as const;

export type LeadscoreConfig = {
  id: string;
  createdAt: string;
  deleted: boolean;
  deletedAt?: string;
  deletedBy?: number;
  accountId: number;
  account?: Account;
  // section 1
  name: string;
  enabled: boolean;
  enrichment: LeadscoreConfigEnrichment;
  // section 2
  rules: LeadscoreConfigRules;
  // section 3
  scoring: LeadscoreConfigScoring;
};

export type LeadscoreConfigScoring = {
  akkioModelId: number;
  threshold: ScoringThreshold;
  rate: number;
};

export type ScoringThreshold = {
  outcome: ScoringThresholdOutcome;
  score: ScoringThresholdScore;
  when: ScoringThresholdWhen;
  from?: number;
  to?: number;
};

export const ScoringThresholdOutcomeOptions = [
  { value: 'positive', text: 'Positive' },
  { value: 'negative', text: 'Negative' },
  { value: 'score', text: 'Score Threshold' },
] as const;

export type ScoringThresholdOutcome = typeof ScoringThresholdOutcomeOptions[number]['value'];

export const ScoringThresholdScoreOptions = [
  { value: 'positive', text: 'Positive Score' },
  { value: 'negative', text: 'Negative Score' },
] as const;

export type ScoringThresholdScore = typeof ScoringThresholdScoreOptions[number]['value'];

export const ScoringThresholdWhenOptions = [
  { value: 'always', text: 'Always' },
  { value: 'between', text: 'Between' },
  { value: 'gte', text: 'Greater than or equal to' },
  { value: 'gt', text: 'Greater than' },
  { value: 'lte', text: 'Less than or equal to' },
  { value: 'lt', text: 'Less than' },
  { value: 'eq', text: 'Equal to' },
] as const;

export type ScoringThresholdWhen = typeof ScoringThresholdWhenOptions[number]['value'];

export type LeadscoreConfigEnrichment = {
  censusEnabled: boolean;
  censusRate: number;
  cimaEnabled: boolean;
  cimaRate: number;
  phoneLookupEnabled: boolean;
  phoneLookupRate: number;
  overrideEnabled: boolean;
};

export type LeadscoreConfigRules = {
  requiredFields: string[] | null;
  filters: RuleFilter[] | null;
};

export type Model = {
  // api_version: string;
  id: number;
  created_at: string; // TODO: parse to Date
  updated_at: string; // TODO: parse to Date
  type: ModelType;
  enabled: boolean;
  source: ModelSource;
  name: string;
  // modelId: string;
  // version: string;
  display_name: string;
  // group: string;
  custom_fields: string[];
  account_access: ModelAccountAccess[];
  versions?: ModelVersion[];
  // downloads: ModelDownload[];
  jobs: PredictJob[] | ScheduleJob[];
  required_fields: string[];
};

export type ModelAccountAccess = {
  model_id: number;
  model?: Model | null;
  account_id: number;
  account?: Account | null;
  added_by_id: number;
  added_by?: User | null;
  added_at: string; // TODO: parse to Date
  rate?: number;
};

export type ModelDownload = {
  id: number;
  model_id: string;
  model_type: number;
  delimiter: Delimiter;
  threshold: number;
  raw_num_rows: number;
  predicted_num_rows: number;
  num_rows_over_threshold: number;
};

export enum ModelSource {
  // 'Lookalike API' = 1,
  'Engagement API' = 2,
  'Databricks' = 3,
}

export enum ModelStage {
  'Development' = 1,
  'Staging' = 2,
  'Production' = 3,
  'Archived' = 4,
}

export enum ModelType {
  // 'Lookalike' = 1,
  'Engagement' = 2,
}

export type ModelVersion = {
  id: number;
  model_id: number;
  enabled: boolean;
  version: string;
  stage: ModelStage;
  status: string;
};

export const ComparisonOperatorOptions = [
  { key: 'eq', value: 'eq', text: 'Equal to' },
  { key: 'neq', value: 'neq', text: 'Not Equal to' },
  { key: 'in', value: 'in', text: 'One Of' },
  { key: 'not_in', value: 'not_in', text: 'Not One Of' },
  { key: 'gt', value: 'gt', text: 'Greater Than' },
  { key: 'gte', value: 'gte', text: 'Greater Than or Equal to' },
  { key: 'lt', value: 'lt', text: 'Less Than' },
  { key: 'lte', value: 'lte', text: 'Less Than or Equal to' },
  { key: 'like', value: 'like', text: 'Like' },
  { key: 'not_like', value: 'not_like', text: 'Not Like' },
  { key: 'null', value: 'null', text: 'Is Null' },
  { key: 'not_null', value: 'not_null', text: 'Not Null' },
  { key: 'time_after', value: 'time_after', text: 'After' },
  { key: 'time_before', value: 'time_before', text: 'Before' },
] as const;

export type ComparisonOperator = typeof ComparisonOperatorOptions[number]['value'];

export const LogicalOperatorOptions = [
  { value: 'AND', text: 'AND' },
  { value: 'OR', text: 'OR' },
] as const;

export type LogicalOperator = typeof LogicalOperatorOptions[number]['value'];

export type PaginatedResponse<T> = {
  offset: number;
  limit: number;
  count: number;
  total: number;
  data: T[];
};

export type Pipeline = {
  id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  account: Account | null;
  user_id: number;
  user: User | null;
  enabled: boolean;
  name: string;
  config: PipelineConfig;
};

export type PipelineConfig = {
  schedule: PipelineSchedule;
  extract: PipelineExtractConfig;
  model: PipelineModelConfig;
  ingest: PipelineIngestConfig;
};

export enum PipelineScheduleFrequency {
  'hours' = 'hours',
  'days' = 'days',
  'weeks' = 'weeks',
  'months' = 'months',
}

export type PipelineSchedule = {
  hour: number; // 0 - 23
  end_hour: number; // 0 - 23
  every: number;
  frequency: PipelineScheduleFrequency;
  timezone: string;
  days: Weekday[]; // 0 - 6 (0: Sunday - 6: Saturday)
};

export const SortDirections = [
  { value: 'ASC', text: 'Ascending' },
  { value: 'DESC', text: 'Descending' },
] as const;

export type SortDirection = typeof SortDirections[number]['value'];
export type SortDirectionText = typeof SortDirections[number]['text'];

export type PipelineExtractConfig = {
  is_legacy: boolean;
  table_id: number;
  dataset_id: string;
  dataset_ids: string[] | null;
  list_id: string;
  list_ids: string[] | null;
  columns: string[];
  filter_set_id: number;
  // filter_set: PipelineExtractFilterSet | null;
  filters: PipelineExtractFilter[];
  sort_column: string;
  sort_direction: SortDirection;
  blacklist_ids: number[];
  exclude_blacklisted: boolean;
  exclude_connects: boolean;
  lead_score: boolean;
  lead_score_min: number;
  lead_score_max: number;
  lead_score_type: 'eq' | 'gte' | 'between';
  limit: number;
};

export type PipelineExtractFilter = {
  column: string;
  operator: string;
  value: string;
};

export type PipelineFilterSet = {
  id: number;
  createdAt: string;
  createdById: number;
  createdBy: User | null;
  name: string;
  filters: PipelineExtractFilter[];
};

export type PipelineModelConfig = {
  enabled: boolean;
  lead_scoring: PipelineLeadScoringConfig;
  engagement_modeling: PipelineEngagementModelingConfig;
};

export type PipelineLeadScoringConfig = {
  enabled: boolean;
  model_id: number;
};

export type PipelineEngagementModelingConfig = {
  model_id: number;
  model_version: string;
  schedule_id: number;
  lookahead: number;
  hourly_capacity: number | null;
  max_hourly_capacity: number | null;
};

export type PipelineIngestConfig = {
  enabled: boolean;
  rename_fields: boolean;
  field_mappings: PipelineIngestFieldMapping[];
  feed_id: number;
};

export type PipelineIngestFieldMapping = {
  src: string;
  dst: string;
};

export type PipelineJob = Job & {
  payload: PipelineJobPayload;
};

export type PipelineJobPayload = JobPayload & {
  pipeline_id: number;
  sql?: string;
  params?: { Name: string; Value: string }[];
  raw_num_rows?: number;
  raw_gs_path?: string;
};

export type PredictJob = Job & {
  payload: PredictJobPayload;
};

export type PredictJobPayload = JobPayload & {
  model_id: string;
  model_type: ModelType;
  delimiter: Delimiter;
  threshold: number;
  raw_num_rows: number;
  raw_gs_path: string;
  parsed_gs_path?: string;
  parsed_num_rows?: number;
  qualified_gs_path?: string;
  qualified_num_rows?: number;
  threshold_counts?: ThresholdCounts;
  predicted_num_rows?: number;
  predicted_gs_path?: string;
  num_rows_over_threshold?: number;
};

export type QualifaiConversation = {
  id: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  name: string;
  dialogflowProjectId: string;
  nodes: Record<string, QualifaiConversationNode>;
  startNode: string;
  sharedOutputs: QualifaiConversationOutput[];
  fronter?: QualifaiConversationGenerativeAgent | null;
  closer?: QualifaiConversationGenerativeAgent | null;
};

export type QualifaiConversationGenerativeAgent = {
  llmProvider: LLMProvider | '';
  questions: QualifaiConversationQuestion[];
  rebuttals: QualifaiConversationQuestion[];
  fallbacks: QualifaiConversationQuestion[];
  hangupEndings: QualifaiConversationQuestion[];
  transferEndings: QualifaiConversationQuestion[];
  evaluationPrompt: string;
  extraInstructions: string;
  transferTo: string;
  callTransferWebhookId: string;
  callTransferUrl: string;
  // pretransferScorecardId: string;
  // scorecardDataMappings: Record<string, any>[];
  // scorecardTransferOverrides: Record<string, any>[];
};

export const DefaultEvaluationPrompt = `Here are the definitions for each category:

Qualified = Prospect qualified based on the questions and answered agreeably to be transferred to a specialist.
Qualified Hesitant = Prospect answered questions as if they were qualified and agreed to be transferred to a specialist but did express some resistance, hesitation or friction during the call.
Not Interested = Prospect expressed not interested or lack of interest in the offerings from the bot
Not Qualified = Prospect did not qualify based on their responses to the qualifying questions
Not Qualified Response = Bot didn't understand the Prospect (the Bot repeated questions or responded in an illogical way)
Prank = Prospect went thru steps as prank
Prank Minor = Minor pranking the bot
DNC = prospect asked to be put on Do Not Call List
Irate = Prospect's sentiment was extremely hostile without profanity
Robot Friction = Prospect was probing the bot about being a robot or AI
Language Barrier = Prospect indicated they did not speak English
Overlap = Prospect speaking at the same time as the BOT causing friction
Profanity = Prospect used profanity and Bot didn't recognize sentiment
Other = Other category not provided in the options

Does the prospect appear to have genuine interest in speaking with someone based on their responses to the bot?`;

export const newQualifaiConversationGenerativeAgent = (): QualifaiConversationGenerativeAgent => ({
  llmProvider: '',
  questions: [],
  rebuttals: [],
  fallbacks: [],
  hangupEndings: [],
  transferEndings: [],
  evaluationPrompt: '',
  extraInstructions: '',
  transferTo: '',
  callTransferWebhookId: '',
  callTransferUrl: '',
  // pretransferScorecardId: '',
  // scorecardDataMappings: [],
  // scorecardTransferOverrides: [],
});

// export type QualifaiConversationFronter = QualifaiConversationGenerativeAgent & Record<string, any>;

// export type QualifaiConversationCloser = QualifaiConversationGenerativeAgent & Record<string, any>;

export type QualifaiDialogflowBot = {
  projectId: string;
  name: string;
  intents?: QualifaiDialogflowIntent[];
};

export type QualifaiDialogflowIntent = {
  id: string;
  name: string;
};

export const DEFAULT_BOT_PROJECT_ID = 'ds-bot-production-hdnr';
export const DEFAULT_SPEECH_PROJECT_ID = 'speech-detection-iysv';

export const LLMProviders = [
  { value: 'openai', text: 'OpenAI / ChatGPT' },
  { value: 'claude', text: 'Claude', disabled: true },
  { value: 'gemini', text: 'Gemini', disabled: true },
  { value: 'llama', text: 'Llama', disabled: true },
] as const;

export type LLMProvider = typeof LLMProviders[number]['value'];

export type QualifaiConversationOutput = {
  contextName: string;
  action: QualifaiConversationNodeAction | '';
  nodeId: string;
};

export type QualifaiConversationQuestion = {
  id: string;
  name: string;
  allowInterruptions: boolean;
  audioUrl: string;
  audioTranscript: string;
  audioDuration: number;
};

export const newQualifaiConversationQuestion = (name: string): QualifaiConversationQuestion => ({
  id: uuid(),
  name,
  allowInterruptions: false,
  audioUrl: '',
  audioTranscript: '',
  audioDuration: 0,
});

export type QualifaiConversationNode = QualifaiConversationQuestion & {
  order: number;
  nextNodeId: string;
  outputs: QualifaiConversationOutput[] | null;
  action: QualifaiConversationNodeAction | '';
  data: Record<string, any> | null;
};

export const QualifaiConversationNodeActions = [
  { value: 'continue', text: 'Continue to Question' },
  { value: 'dnc', text: 'Do Not Call' },
  { value: 'hangup', text: 'Hangup' },
  { value: 'transfer', text: 'Transfer' },
  { value: 'fronter', text: 'Hand off to Fronter' },
  // { value: 'closer', text: 'Hand off to Closer' },
] as const;

export type QualifaiConversationNodeAction = typeof QualifaiConversationNodeActions[number]['value'];

export type QualifaiScorecard = {
  id: string;
  accountId: string;
  createdAt: string;
  title: string;
  description: string;
  llm: string;
  status: string;
  sections: QualifaiScorecardSection[];
  // ... a bunch of other stuff
};

export type QualifaiScorecardSection = {
  id: string;
  order: number;
  scorecardItems: QualifaiScorecardItem[];
  // ... a bunch of other stuff
};

export type QualifaiScorecardItem = {
  id: string;
  order: number;
  reportingLabel: string;
  question: string;
  selectOptions: QualifaiScorecardItemSelectOption[];
  // ... a bunch of other stuff
};

export type QualifaiScorecardItemSelectOption = {
  id: string;
  order: number;
  label: string;
  // ... a bunch of other stuff
};

export const ReportingTimeRanges = [
  { value: 'today', text: 'Today' },
  { value: 'yesterday', text: 'Yesterday' },
  { value: 'this week', text: 'This week' },
  { value: 'last week', text: 'Last week' },
  { value: 'this month', text: 'This month' },
  { value: 'last month', text: 'Last month' },
  { value: 'previous month', text: 'Previous Month' },
  { value: 'last 90 days', text: 'Last 90 days' },
  { value: 'this year', text: 'YTD' },
  { value: 'last year', text: 'Last year' },
  { value: 'custom', text: 'Custom' },
] as const;

export type ReportingTimeRange = typeof ReportingTimeRanges[number]['value'];

export const RequestMethods = [
  { value: 'GET', text: 'GET' },
  { value: 'POST', text: 'POST' },
] as const;

export type RequestMethod = typeof RequestMethods[number]['value'];

export const RequestContentTypes = [
  { value: 'QUERY', text: 'Query' },
  { value: 'FORM', text: 'Form' },
  { value: 'JSON', text: 'JSON' },
] as const;

export type RequestContentType = typeof RequestContentTypes[number]['value'];

export const ResponseContentTypes = [
  { value: 'TEXT', text: 'Plain Text' },
  { value: 'JSON', text: 'JSON' },
  { value: 'XML', text: 'XML' },
] as const;

export type ResponseContentType = typeof ResponseContentTypes[number]['value'];

export type Schedule = {
  id: number;
  name: string;
  enabled: boolean;
  timezone?: string;
  config: ScheduleConfig;
};

export type ScheduleConfig = {
  [key in WeekdayShortName]: ScheduleConfigDay;
};

export type ScheduleConfigDay = {
  enabled: boolean;
  start: number;
  end: number;
};

export type ScheduleJob = Job & {
  payload: ScheduleJobPayload;
};

export type ScheduleJobPayload = JobPayload & {
  model_id: string;
  model_name: string;
  model_version: string;
  model_type: ModelType;
  job_id: number;
  run_id: number;
  delimiter: Delimiter;
  schedule: Schedule;
  start_date: string;
  lookahead: number;
  hourly_capacity: number;
  // NOTE: Older data will be undefined, while newer data will be null
  feed_id?: number | null;
  pipeline_id?: number | null;
  raw_num_rows: number;
  raw_gs_path: string;
  parsed_num_rows?: number;
  parsed_gs_path?: string;
  predicted_num_rows?: number;
  predicted_gs_path?: string;
  engagement_counts?: EngagementCounts;
  threshold_counts?: ThresholdCounts;
  num_rows_over_threshold?: number;
};

export type SendgridProfile = {
  id: number;
  accountId: number;
  account: Account | null;
  createdAt: string | Date;
  apiKey: string | null;
  hasValidApiKey: boolean;
  lastCrawledAt: string;
  webhookEventsSetting?: SendgridWebhookEventsSetting;
  inboundParseSettings?: SendgridInboundParseSetting[];
};

export type SendgridInboundParseSetting = {
  id: number;
  profileId: number;
  profile: SendgridProfile | null;
  createdAt: string;
  name: string;
  token: string;
  actions: SendgridInboundParseAction[] | null;
  defaultResponse?: SendgridInboundParseDefaultResponse;
  // From Sendgrid
  // url: string;
  // hostname: string;
  // spam_check: boolean;
  // send_raw: boolean;
};

export type SendgridInboundParseDefaultResponse = {
  enabled: boolean;
  fromEmail: string;
  fromName: string;
  replyToEmail: string;
  subject: string;
  bodyHtml: string;
  bodyText: string;
};

export type SendgridInboundParseAction = {
  id: number;
  settingId: number;
  setting: SendgridInboundParseSetting | null;
  name: string;
  rules: SendgridInboundParseActionRule[] | null;
  triggers: SendgridInboundParseActionTrigger[] | null;
};

export type SendgridInboundParseActionRule = {
  id: number;
  actionId?: number;
  action?: SendgridInboundParseAction | null;
  createdAt?: string;
  order: number;
  field: string;
  operator: string;
  value: string;
};

export type SendgridInboundParseActionTrigger = {
  id: number;
  actionId?: number;
  action?: SendgridInboundParseAction | null;
  createdAt?: string;
  order: number;
  next: string;
  payload: any;
};

export type SendgridWebhookEventsSetting = {
  id: number;
  profileId: number;
  profile: SendgridProfile | null;
  createdAt: string;
  // From Sendgrid
  enabled: boolean;
  url: string;
};

export type TelnyxProfile = {
  id: number;
  account_id: number;
  account: Account | null;
  created_at: string | Date;
  billing_group_id: string | null;
  purchase_limit: number;
};

export type TelnyxAvailablePhone = {
  phone_number: string;
  friendly_name: string;
  description: string;
  locality: string | null;
  region: string | null;
  postal_code: string | null;
};

export enum TelnyxAvailablePhoneSearchType {
  'area-code' = 'area-code',
  'contains' = 'contains',
  'locality' = 'locality',
}

export type TelnyxPhone = {
  id: number;
  created_at: string;
  updated_at: string;
  sid: string;
  phone_number: string;
  friendly_name: string;
  status: string;
  billing_group_id: string;
  connection_id: string;
  connection_name: string;
  raw_data: any;
};

export type TelnyxPhonesRefetchJob = Job & {
  payload: TelnyxPhonesRefetchJobPayload;
};

export type TelnyxPhonesRefetchJobPayload = JobPayload & {
  accountId: string;
};

export type TelnyxTexmlApp = {
  id: string;
  friendly_name: string;
  active: boolean;
  voice_url: string;
  voice_fallback_url: string;
  voice_method: string;
  status_callback: string;
  status_callback_method: string;
  created_at: string;
  updated_at: string;
};

export type ThresholdCounts = {
  '0.0': number;
  '0.1': number;
  '0.2': number;
  '0.3': number;
  '0.4': number;
  '0.5': number;
  '0.6': number;
  '0.7': number;
  '0.8': number;
  '0.9': number;
  '1.0': number;
};

export type ThresholdRange = { start?: number; end?: number; total: number };

export const TimeRanges = [
  { value: 3600, text: '1 Hour' },
  { value: 7200, text: '2 Hours' },
  { value: 14400, text: '4 Hours' },
  { value: 28800, text: '8 Hours' },
  { value: 86400, text: '1 Day' },
  { value: 172800, text: '2 Days' },
  { value: 259200, text: '3 Days' },
  { value: 345600, text: '4 Days' },
  { value: 432000, text: '5 Days' },
  { value: 518400, text: '6 Days' },
  { value: 604800, text: '1 Week' },
  { value: 1209600, text: '2 Weeks' },
  { value: 1814400, text: '3 Weeks' },
  { value: 2628000, text: '1 Month' },
  { value: 7884000, text: '3 Months' },
] as const;

export type TimeRange = typeof TimeRanges[number]['value'];

export type TrustHubCustomerProfile = {
  sid: string;
  account_sid: string;
  policy_sid: string;
  friendly_name: string;
  status: string;
  valid_until: string; //time.Time
  email: string;
  status_callback: string;
  date_created: string; // time.Time
  date_updated: string; // time.Time
  url: string;
  // links: map[string]interface{}
};

export type TrustHubTrustProductAssignments = {
  sid: string;
  trust_product_sid: string;
  account_sid: string;
  object_sid: string;
  date_created: string;
  url: string;
};

export type TrustHubTrustProduct = {
  sid: string;
  account_sid: string;
  policy_sid: string;
  friendly_name: string;
  status: string;
  valid_until: string; // time.Time
  email: string;
  status_callback: string;
  date_created: string; // time.Time
  date_updated: string; // time.Time
  url: string;
  // links *map[string]interface{}
};

export type TwilioProfile = {
  id: number;
  account_id: number;
  account: Account | null;
  created_at: string | Date;
  name: string | null;
  account_sid: string | null;
  auth_token: string | null;
  phone_numbers?: string[];
  purchase_limit: number;
};

export type TwilioAvailablePhone = {
  phone_number: string;
  friendly_name: string;
  description: string;
  locality: string | null;
  region: string | null;
  postal_code: string | null;
};

export enum TwilioAvailablePhoneSearchType {
  'area-code' = 'area-code',
  'contains' = 'contains',
  'locality' = 'locality',
}

export type TwilioCustomerProfile = {
  id: number;
  customer_profile_sid: string | null;
  business_information_sid: string | null;
  primary_contact_sid: string | null;
  secondary_contact_sid: string | null;
  business_address_sid: string | null;
  supporting_document_sid: string | null;
  shaken_stir_profile_sid: string | null;
  cnam_profile_sid: string | null;
  voice_integrity_profile_sid: string | null;
};

export type TwilioIncomingPhone = {
  created_at: string;
  sid: string;
  phone_number: string;
  friendly_name: string;
  status: string;
  sms_url: string;
  sms_flow_sid: string;
  voice_url: string;
  voice_flow_sid: string;
  customer_profile_sid: string | null;
  shaken_stir_profile_sid: string | null;
  cnam_profile_sid: string | null;
  voice_integrity_profile_sid: string | null;
};

export type TwilioStudioFlow = {
  sid: string;
  status: string;
  friendly_name: string;
  url: string;
  webhook_url: string;
};

export type TwilioIncomingPhonesRefetchJob = Job & {
  payload: TwilioIncomingPhonesRefetchJobPayload;
};

export type TwilioIncomingPhonesRefetchJobPayload = JobPayload & {
  accountId: string;
};

export type TwilioIncomingPhonesReassignTrustProfilesJob = Job & {
  payload: TwilioIncomingPhonesReassignTrustProfilesJobPayload;
};

export type TwilioIncomingPhonesReassignTrustProfilesJobPayload = JobPayload & {
  accountId: string;
  sids: string[];
};

export type User = {
  id: number;
  email: string;
  role: UserRole;
  firstname?: string;
  lastname?: string;
  tos_accepted_at?: string;
  enabled: boolean;
  active_account_id: number;
  active_account: Account;
  last_login: UserLogin | null;
  logins: UserLogin[] | null;
};

export type UserInvite = {
  email: string;
  expires_at: string;
  token: string;
};

export type UserLogin = {
  id: number;
  user_id: number;
  user: User | null;
  created_at: string;
  ip_address: string;
};

export const UserRoles = [
  { value: 'admin', text: 'Admin' },
  { value: 'user', text: 'User' },
  // { value: 'subuser', text: 'Subuser' },
] as const;

export type UserRole = typeof UserRoles[number]['value'];

export type VoiceConfig = {
  id: string;
  created_at: string;
  updated_at: string;
  account_id: number;
  account: Account | null;
  enabled: boolean;
  provider: VoiceProvider | '' | null;
  router_outbound: VoiceRouter | '' | null;
  human_detection: HumanDetectionConfig;
  name: string;
  schedule: Schedule | null;
  messaging: VoiceMessagingConfig | null;
  inbound: VoiceInboundConfig;
  outbound: VoiceOutboundConfig;
  billing_rate: number | null;
  billing_type: BillingConnectProType | '' | null;
};

export const HumanDetectionEndpoints = [
  { value: 'round-robin', text: 'Default (Round Robin)' },
  { value: 'us-west1', text: 'us-west1' },
  { value: 'us-west2', text: 'us-west2' },
  { value: 'us-west4', text: 'us-west4' },
  { value: 'us-central1', text: 'us-central1' },
  { value: 'us-east1', text: 'us-east1' },
  { value: 'us-east4', text: 'us-east4' },
  { value: 'dev.us-west1', text: '(dev) us-west1' },
] as const;

export type HumanDetectionEndpoint = typeof HumanDetectionEndpoints[number]['value'];

export const HumanDetectionSpeechModels = [
  { value: 'whisper_vad', text: 'Whisper VAD' },
  { value: 'assemblyai', text: 'AssemblyAI' },
  { value: 'basic_amd', text: 'Basic AMD' },
] as const;

export type HumanDetectionSpeechModel = typeof HumanDetectionSpeechModels[number]['value'];

export type HumanDetectionConfig = {
  enabled: boolean;
  endpoint: HumanDetectionEndpoint | '';
  speech_detection_model: HumanDetectionSpeechModel | '';
  speech_dialogflow_project_id: string;
  exclude_machines_from_reporting: boolean;
};

// NOTE: Order of these options matters since they are used in a dropdown
export const VoiceProviders = [
  {
    value: 'bandwidth',
    text: 'Bandwidth',
  },
  {
    value: 'twilio',
    text: 'Twilio',
  },
  {
    value: 'telnyx',
    text: 'Telnyx',
  },
] as const;

export type VoiceProvider = typeof VoiceProviders[number]['value'];

export type VoiceRouterOption = {
  value: string;
  text: string;
  providers: readonly VoiceProvider[];
};

// NOTE: Order of these options matters since they are used in a dropdown
export const VoiceRouterOptions: readonly VoiceRouterOption[] = [
  {
    value: 'bandwidth',
    text: 'Bandwith',
    providers: ['bandwidth'],
  },
  {
    value: 'twilio',
    text: 'Twilio',
    providers: ['twilio'],
  },
  {
    value: 'telnyx',
    text: 'Telnyx',
    providers: ['telnyx'],
  },
  {
    value: 'commio',
    text: 'Commio',
    providers: ['twilio'],
  },
] as const;
export type VoiceRouter = typeof VoiceRouterOptions[number]['value'];

export type VoiceMessagingConfig = {
  blacklist_ids: number[];
  dnc_blacklist_id: number;
};

export type VoiceInboundConfig = {
  blacklist_ids: number[];
  call_transfer_url: string;
  call_transfer_webhook_id: string;
  conversation_id: string;
  enable_call_recordings: boolean;
  ivr_audio_url: string;
  ivr_blacklist_id: number;
  template: VoiceConfigTemplate;
  transfer_to: string;
  vm_greeting_audio_url: string;
};

export type VoiceOutboundConfig = {
  blacklist_ids: number[];
  call_transfer_url: string;
  call_transfer_webhook_id: string;
  conversation_id: string;
  enable_call_recordings: boolean;
  ivr_audio_url: string;
  ivr_blacklist_id: number;
  ring_timeout: number;
  template: VoiceConfigTemplate;
  transfer_to: string;
  vm_message_enabled: boolean;
  vm_message_audio_url: string;
  rate_limit_enabled: boolean;
  rate_limit_value: number;
  predictive_rate_limit_visible: boolean;
  predictive_rate_limit_campaign_ids: string;
  predictive_rate_limit_enabled: boolean;
  predictive_rate_limit_value: number;
};

export const VoiceConfigTemplates = [
  { value: 'direct_transfer', text: 'Direct Transfer' },
  { value: 'agentai_bot', text: 'AgentAi Bot', disabled: true, description: 'Disabled' },
  { value: 'ivr', text: 'IVR' },
  { value: 'conversation', text: 'Conversation' },
] as const;

export type VoiceConfigTemplate = typeof VoiceConfigTemplates[number]['value'];

export type WebhookConfig = {
  id: string;
  account_id: number;
  account?: Account;
  // General
  enabled: boolean;
  advanced: boolean;
  appendCustomerData: boolean;
  name: string;
  reject_on_customer_not_found: boolean;
  custom_fields: WebhookField[] | null;
  data_enrichment: boolean;
  data_enrichment_rate: number;
  data_enrichment_override: boolean;
  // Request
  request: WebhookRequest;
  data: { [key: string]: string };
  field_mappings: FieldMapping[] | null;
  transformations: WebhookTransformation[] | null;
  // Post Response Processing
  response: WebhookResponseConfig;
};

export type WebhookResponseConfig = {
  contentType: ResponseContentType;
  fields: WebhookResponseField[];
};

export type WebhookResponseField = {
  id: string;
  pattern: string;
  key: string;
};

export const newWebhookResponseField = (): WebhookResponseField => ({
  id: uuid(),
  pattern: '',
  key: '',
});

export type WebhookTransformation = {
  id: string;
  fields: string[];
  from: string;
  to: string;
};

export const newWebhookTransformation = (): WebhookTransformation => ({
  id: uuid(),
  fields: [],
  from: '',
  to: '',
});

export type WebhookField = {
  id: string;
  name: string;
  type: WebhookFieldType;
};

export type Table = {
  table_id: string;
  columns: string[];
};

export type FieldMapping = {
  from: string;
  to: string;
  type: WebhookFieldType;
  time_format?: string | null;
};

export const WebhookFieldTypes = ['STRING', 'INT', 'FLOAT', 'BOOL', 'TIMESTAMP'] as const;

export const WebhookFieldTimeFormats = ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'HH:mm:ss'] as const;

export type WebhookFieldType = typeof WebhookFieldTypes[number];

export type WebhookFieldTimeFormat = typeof WebhookFieldTimeFormats[number];

export type WebhookRequest = {
  url: string;
  method: RequestMethod;
  headers: { [key: string]: string };
  timeout: number;
  content_type: RequestContentType;
  body_template: string | null;
  success_text: string;
};

export type WebhookLog = {
  id: number;
  accountId: number;
  createdAt: string;
  startedAt: string;
  endedAt: string;
  durationMs: number | null;
  slug: string;
  localReqData: string;
  localResStatus: number;
  localError: string | null;
  proxyReqData: string | null;
  proxyResData: string | null;
  proxyResStatus: number | null;
  proxyError: string | null;
  proxyReqMs: number | null;
};

export type VoiceCallDetailRecord = {
  id: string;
  voiceConfigId: string;
  voiceConfigName: string;
  createdAt: string;
  updatedAt: string;
  source: string;
  direction: VoiceCallDirection;
  phone: string;
  dialerPhone: string;
  disposition: string;
  digits: string;
  startedAt: string;
  endedAt: string;
  durationMs: number;
  queueTime: number;
  transferCallId: string;
  transferStartedAt: string;
  transferEndedAt: string;
  transferDurationMs: number;
  transferTo: string;
  transferCallerId: string;
  recordingDuration: number;
  recordingId: string;
  recordingStoragePath: string;
  recordingUrl: string;
  speechDurationMs: number;
  speechPredictionCount: number;
  speechPredictionLabel: string;
  conversationId: string;
  conversationName: string;
  sttProvider: string;
  dialogDurationMs: number;
  transcript: string;
  scorecardId: string;
};

export const VoiceCallDirections = [
  { value: 'inbound', text: 'Inbound' },
  { value: 'outbound-api', text: 'Outbound' },
] as const;

export type VoiceCallDirection = typeof VoiceCallDirections[number]['value'];

export type VoiceRecording = {
  id: string;
  created_at: string;
  listened_at: string | null;
  account_id: number;
  voice_config_id: string;
  source: VoiceProvider;
  type: VoiceRecordingType;
  favorite: boolean | false;
  archived: boolean | false;
  call_sid: string;
  phone_number: string;
  direction: string;
  duration: string;
  url: string;
};

export const VoiceRecordingTypes = [
  { value: 'call', text: 'Call' },
  { value: 'voicemail', text: 'Voicemail' },
] as const;

export type VoiceRecordingType = typeof VoiceRecordingTypes[number]['value'];

export const BaseSchema: DatasetField[] = [
  { id: 'phone', name: 'phone', friendly_name: 'Phone', type: 'STRING' },
  { id: 'email', name: 'email', friendly_name: 'Email', type: 'STRING' },
  { id: 'firstname', name: 'firstname', friendly_name: 'First Name', type: 'STRING' },
  { id: 'lastname', name: 'lastname', friendly_name: 'Last name', type: 'STRING' },
  { id: 'address1', name: 'address1', friendly_name: 'Address 1', type: 'STRING' },
  { id: 'address2', name: 'address2', friendly_name: 'Address 2', type: 'STRING' },
  { id: 'city', name: 'city', friendly_name: 'City', type: 'STRING' },
  { id: 'state', name: 'state', friendly_name: 'State', type: 'STRING' },
  { id: 'zip', name: 'zip', friendly_name: 'Zip', type: 'STRING' },
  { id: 'dob', name: 'dob', friendly_name: 'Date of Birth', type: 'STRING' },
  { id: 'datetime', name: 'datetime', friendly_name: 'Datetime', type: 'TIMESTAMP' },
  { id: 'ip', name: 'ip', friendly_name: 'IP Address', type: 'STRING' },
  { id: 'source', name: 'source', friendly_name: 'Source', type: 'STRING' },
  { id: 'called_count', name: 'called_count', friendly_name: 'Called Count', type: 'NUMBER' },
];

export const TimezoneOptions: DropdownItemProps[] = [
  { text: 'Pacific/Honolulu (Hawaii)', value: 'Pacific/Honolulu' }, // Hawaii
  { text: 'America/Anchorage (Alaska)', value: 'America/Anchorage' }, // Alaska
  { text: 'America/Los_Angeles (Pacific Time)', value: 'America/Los_Angeles' }, // Pacific
  { text: 'America/Denver (Mountain Time)', value: 'America/Denver' }, // Mountain
  { text: 'America/Chicago (Central Time)', value: 'America/Chicago' }, // Central
  { text: 'America/New_York (Eastern Time)', value: 'America/New_York' }, // Eastern
  { text: 'Etc/UTC (Coordinated Universal Time)', value: 'Etc/UTC' },
];
