import { endOfDay, startOfDay } from 'date-fns';
import { useQuery } from 'react-query';

import {
  PaginatedResponse,
  ReportingTimeRange,
  SortDirection,
  VoiceCallDetailRecord,
  VoiceCallDirection,
} from 'src/types';
import { ApiResponse, http } from './http-common';

export type VoiceCallsResponse = ApiResponse & {
  calls: PaginatedResponse<VoiceCallDetailRecord>;
};

export type VoiceCallsDispositionsResponse = ApiResponse & {
  dispositions?: Array<string>;
};

export type VoiceCallsFilters = {
  timeRange: ReportingTimeRange;
  startDate: Date;
  endDate: Date;
  sortBy: string;
  sortDirection: SortDirection;
  search?: string;
  directions?: VoiceCallDirection[];
  dispositions?: string[];
  voiceConfigIDs?: string[];
  conversationIDs?: string[];
  hasRecording?: string;
};

export type ListVoiceCallsParams = VoiceCallsFilters & {
  limit: number;
  offset: number;
};

export const useListVoiceCallsQuery = (params: ListVoiceCallsParams) => {
  params.startDate = startOfDay(params.startDate);
  params.endDate = endOfDay(params.endDate);

  return useQuery(['voice', 'calls', params], async () => {
    const res = await http.get<VoiceCallsResponse>('/api/voice/calls', { params });
    return res.data.calls;
  });
};

export const useGetVoiceCallsDispositionsQuery = () => {
  return useQuery(['voice', 'calls', 'dispositions'], async () => {
    const res = await http.get<VoiceCallsDispositionsResponse>(`/api/voice/calls/dispositions`);
    return res.data.dispositions;
  });
};
