import { format } from 'date-fns';
import { Button, Header, Icon, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { Note, Row } from 'src/styles';
import { VoiceCallDetailRecord } from 'src/types';

type Props = { call: VoiceCallDetailRecord };

const VoiceCallDetailsModal = ({ call }: Props) => {
  return (
    <Modal
      size="fullscreen"
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Content>
        <Row style={{ gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <Header>Call Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Call ID</Table.Cell>
                  <Table.Cell>
                    <Note>{call.id}</Note>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ width: 130 }}>Started At</Table.Cell>
                  <Table.Cell>{format(new Date(call.startedAt), 'PPPppp')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ width: 130 }}>Ended At</Table.Cell>
                  <Table.Cell>{format(new Date(call.endedAt), 'PPPppp')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Customer Phone</Table.Cell>
                  <Table.Cell>{call.phone}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Dialer Phone</Table.Cell>
                  <Table.Cell>{call.dialerPhone}</Table.Cell>
                </Table.Row>

                {call.transferCallId && (
                  <>
                    <Table.Row>
                      <Table.Cell>Transfer Call ID</Table.Cell>
                      <Table.Cell>
                        <Note>{call.transferCallId}</Note>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer To</Table.Cell>
                      <Table.Cell>{call.transferTo}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell style={{ minWidth: 170 }}>Transfer Duration (ms)</Table.Cell>
                      <Table.Cell>{call.transferDurationMs}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer Started At</Table.Cell>
                      <Table.Cell>{format(new Date(call.transferStartedAt), 'PPPppp')}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer Ended At</Table.Cell>
                      <Table.Cell>{format(new Date(call.transferEndedAt), 'PPPppp')}</Table.Cell>
                    </Table.Row>
                  </>
                )}

                <Table.Row>
                  <Table.Cell>Disposition</Table.Cell>
                  <Table.Cell>{call.disposition && <Label>{call.disposition}</Label>}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Direction</Table.Cell>
                  <Table.Cell>
                    <Label>{call.direction}</Label>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Voice Config</Table.Cell>
                  <Table.Cell>{call.voiceConfigName}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Conversation</Table.Cell>
                  <Table.Cell>{call.conversationName}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Scorecard ID</Table.Cell>
                  <Table.Cell>
                    <Note>{call.scorecardId}</Note>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Source</Table.Cell>
                  <Table.Cell>
                    <Label>{call.source}</Label>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Call Duration (ms)</Table.Cell>
                  <Table.Cell>{call.durationMs}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Dialog Duration (ms)</Table.Cell>
                  <Table.Cell>{call.dialogDurationMs}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ minWidth: 160 }}>Speech Duration (ms)</Table.Cell>
                  <Table.Cell>{call.speechDurationMs}</Table.Cell>
                </Table.Row>

                {call.recordingId && (
                  <>
                    <Table.Row>
                      <Table.Cell>Recording ID</Table.Cell>
                      <Table.Cell>
                        <Note>{call.recordingId}</Note>
                      </Table.Cell>
                    </Table.Row>

                    {call.recordingDuration > 0 && (
                      <Table.Row>
                        <Table.Cell>Recording Duration</Table.Cell>
                        <Table.Cell>{call.recordingDuration}</Table.Cell>
                      </Table.Row>
                    )}

                    <Table.Row>
                      <Table.Cell>Recording</Table.Cell>
                      <Table.Cell>
                        <audio
                          controls
                          src={call.recordingUrl}
                          style={{ display: 'block', height: '3rem', width: '100%' }}
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Recording Storage Path</Table.Cell>
                      <Table.Cell>
                        <Note>{call.recordingStoragePath}</Note>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Table.Body>
            </Table>
          </div>

          {!!call.transcript && (
            <div style={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
              <Header>Transcript</Header>
              <Segment style={{ flex: 1, marginTop: 0 }}>
                <pre style={{ margin: 0, overflowY: 'auto' }}>{call.transcript}</pre>
              </Segment>
            </div>
          )}
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default VoiceCallDetailsModal;
