import { cloneDeep, set } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CheckboxProps,
  Divider,
  DropdownProps,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  Label,
} from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { BillingPaymentAmounts } from 'src/config';
import { StyledFieldset } from 'src/styles';
import theme from 'src/styles/theme';
import { Account, AccountBilling, BillingConnectProTypes } from 'src/types';
import { formatBilling } from 'src/utils';
import AdminAccountBalanceAdjustment from './AdminAccountBalanceAdjustment';
import AdminAccountBillingVoiceConfigs from './AdminAccountBillingVoiceConfigs';

type ValidationErrors = {
  connectProRate?: string;
  connectProSubscriptionDay?: string;
  connectProMinimumSeconds?: string;
  connectProIntervalSeconds?: string;
};

type Props = {
  account: Account;
};

const AdminAccountBillingConnectPro = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<AccountBilling>(() => cloneDeep(account.billing));
  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChange = useCallback((_, { name, checked, value }: CheckboxProps | DropdownProps | InputOnChangeData) => {
    setFormdata(prev => {
      const v = typeof checked !== 'undefined' ? checked : String(value);

      const next = cloneDeep(prev);
      set(next, name, v);
      return next;
    });
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    const billing = formatBilling(formdata);

    const validationErrors: ValidationErrors = {};

    if (!billing.connectPro.rate) {
      validationErrors.connectProRate = 'required';
    }

    if (billing.connectPro.type === 'monthly') {
      if (billing.connectPro.subscription.day === 0) {
        validationErrors.connectProSubscriptionDay = 'required';
      } else if (Number(billing.connectPro.subscription.day) < 1) {
        validationErrors.connectProSubscriptionDay = 'must be greater than 0';
      } else if (Number(billing.connectPro.subscription.day) > 28) {
        validationErrors.connectProSubscriptionDay = 'must be less than 29';
      }
    }

    setErrors(validationErrors);
    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, billing });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, formdata, mutateAsync]);

  const currentBalance = Number(account.currentBalanceConnectPro || 0);

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={onSubmit}>
            <Header as="h4">Settings</Header>

            <ApiMessage data={apiMessage} />

            <Form.Checkbox
              toggle
              name="connectPro.enabled"
              label="Connect Pro Billing Enabled?"
              checked={formdata.connectPro.enabled}
              onChange={onChange}
            />

            <div style={{ opacity: formdata.connectPro.enabled ? undefined : 0.5 }}>
              <Form.Group widths="equal">
                <Form.Input
                  width={10}
                  label={
                    <label>
                      Rate <span style={{ color: theme.gray }}>(in dollars)</span>
                    </label>
                  }
                  name="connectPro.rate"
                  value={String(formdata.connectPro.rate || '')}
                  onChange={onChange}
                  error={errors.connectProRate}
                />

                <Form.Select
                  fluid
                  width={10}
                  label="Type"
                  name="connectPro.type"
                  value={formdata.connectPro.type}
                  onChange={onChange}
                  options={BillingConnectProTypes.map(t => ({
                    ...t,
                    key: t.value,
                  }))}
                />

                {formdata.connectPro.type === 'monthly' && (
                  <Form.Input
                    fluid
                    width={6}
                    label="Day of the Month"
                    name="connectPro.subscription.day"
                    value={formdata.connectPro.subscription.day}
                    onChange={onChange}
                    error={errors.connectProSubscriptionDay}
                  />
                )}
              </Form.Group>

              <StyledFieldset>
                <legend>Per Minute Settings</legend>

                <Form.Group widths="equal">
                  <Form.Input
                    placeholder="60"
                    label={
                      <label>
                        Minimum <span style={{ color: theme.gray }}>(Seconds)</span>
                      </label>
                    }
                    name="connectPro.minimumSeconds"
                    value={formdata.connectPro.minimumSeconds || ''}
                    onChange={onChange}
                    error={errors.connectProMinimumSeconds}
                  />

                  <div style={{ display: 'flex', alignItems: 'center', padding: '1rem 1rem 0' }}>
                    <span style={{ color: theme.gray }}>x</span>
                  </div>

                  <Form.Input
                    placeholder="30"
                    label={
                      <label>
                        Interval <span style={{ color: theme.gray }}>(Seconds)</span>
                      </label>
                    }
                    name="connectPro.intervalSeconds"
                    value={formdata.connectPro.intervalSeconds || ''}
                    onChange={onChange}
                    error={errors.connectProIntervalSeconds}
                  />
                </Form.Group>
              </StyledFieldset>

              <Divider />

              <Form.Group>
                <Form.Select
                  width={10}
                  fluid
                  label="Deposit Amount"
                  name="connectPro.deposit.amount"
                  value={String(formdata.connectPro.deposit.amount)}
                  onChange={onChange}
                  options={[0, ...BillingPaymentAmounts].map(a => ({
                    key: a,
                    value: String(a),
                    text: '$' + a.toLocaleString(),
                  }))}
                />
                <Form.Select
                  width={6}
                  fluid
                  label="Paid"
                  name="connectPro.deposit.paid"
                  value={formdata.connectPro.deposit.paid ? 'yes' : 'no'}
                  onChange={onChange}
                  options={[
                    { key: 'yes', value: 'yes', text: 'Yes' },
                    { key: 'no', value: 'no', text: 'No' },
                  ]}
                />
              </Form.Group>
            </div>

            <Button color="blue" loading={isLoading}>
              Save
            </Button>
          </Form>
        </Grid.Column>

        <Grid.Column>
          <Header as="h4">Current Balance</Header>

          <p>
            <Label
              as="span"
              color={currentBalance <= 0 ? 'red' : 'green'}
              size="large"
              style={{ marginRight: '0.25em' }}
            >
              {currentBalance.toFixed(2)}
            </Label>

            <AdminAccountBalanceAdjustment account={account} billingConfig="connectPro" />
          </p>

          <Link to={`/admin/accounts/${account.id}/billing/connect-pro/history`}>
            <Button as="span" color="blue" compact>
              <Icon name="list ul" /> History
            </Button>
          </Link>
        </Grid.Column>
      </Grid.Row>

      {formdata.connectPro.type !== 'monthly' && (
        <Grid.Row>
          <AdminAccountBillingVoiceConfigs
            defaultRate={formdata.connectPro.rate || 0}
            defaultType={formdata.connectPro.type}
          />
        </Grid.Row>
      )}
    </Grid>
  );
};

export default AdminAccountBillingConnectPro;
